export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const timeToYYYYMMDD = (timestamp) => {
  let date = new Date(Number(timestamp));
  let day = (date.getDate() < 10 ? '0' : '') + date.getDate();
  let month = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1);
  let year = (date.getFullYear() < 10 ? '0' : '') + date.getFullYear();
  return `${year}-${month}-${day}`;
};
export const timeToYYYYMMDDHH = (timestamp) => {
  let date = new Date(Number(timestamp));
  let day = (date.getDate() < 10 ? '0' : '') + date.getDate();
  let month = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1);
  let year = date.getFullYear();
  let hour = date.getHours();
  let ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12;
  hour = hour ? hour : 12; // the hour '0' should be '12'

  return `${year}-${month}-${day} ${hour}${ampm}`;
};

export const dateFormat = (time) => {
  const date = new Date(time);
  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '/' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    '/' +
    date.getFullYear()
  );
};

export const nFormatter = (num, digits = 1) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
};

function numberExponentToLarge(numIn) {
  let str = String(numIn);
  let sign = '';

  if (str[0] === '-') {
    str = str.substring(1);
    sign = '-';
  }

  let [base, power] = str.split(/[eE]/);

  if (!power) {
    return sign + str;
  }

  if (+power === 0 || +power === -0) {
    return sign + base;
  }

  const deciSp = (1.1).toLocaleString().substring(1, 2);

  let [baseLH, baseRH = ''] = base.split(deciSp);

  if (+power > 0) {
    baseRH += '0'.repeat(+power - baseRH.length);
    baseRH = baseRH.slice(0, +power) + deciSp + baseRH.slice(+power);
    if (baseRH.endsWith(deciSp)) {
      baseRH = baseRH.slice(0, -1);
    }
  } else {
    const num = Math.abs(+power) - baseLH.length;
    baseLH = '0'.repeat(num) + baseLH;
    baseLH = baseLH.slice(0, +power) + deciSp + baseLH.slice(+power);
    if (baseLH.startsWith(deciSp)) {
      baseLH = '0' + baseLH;
    }
  }

  return sign + baseLH + baseRH;
}

function isNormalNumber(num) {
  let regex = /^[+-]?[0-9]+(\.[0-9]+)?e[+-][0-9]+$/;
  return regex.test(num);
}

export const numberRounder = (number, tofixed = 2) => {
  if (number < 0.01 && number > -0.01) return 0;
  number = Number(number);
  const checkNumber = isNormalNumber(number);
  const FinalNumber = checkNumber ? numberExponentToLarge(number) : number;
  const numbers = FinalNumber?.toString()?.split('.');

  if (numbers.length === 2) {
    numbers[1] = numbers[1]?.substring(0, tofixed);
    let temp = Number(numbers[1]);
    let isMoreThan10 = temp >= 10;
    temp = temp - 1;

    if (numbers[1].length === 2) {
      numbers[1] = isMoreThan10 ? String(temp) : String('0' + temp);
    } else if (numbers[1].length === 1) {
      numbers[1] = isMoreThan10 ? String(temp) : String(temp);
    }

  }

  return numbers[1] > 0 ? numbers.join('.') : numbers[0];
};

export const numberWithCommasOld = (number, tofixed, floor) => {
  if (tofixed) number = Number(number)?.toFixed(tofixed);

  if (floor) number = Math?.floor(Number(number));

  const str = number?.toString()?.split('.');
  str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');

  return str[1] > 0 ? str.join('.') : str[0];
};

export const numberWithCommas = (number, tofixed = 2) => {
  if (number < 0.01 && number > -0.01) return 0;
  number = Number(number);
  const checkNumber = isNormalNumber(number);
  const FinalNumber = checkNumber ? numberExponentToLarge(number) : number;
  const numbers = FinalNumber?.toString()?.split('.');
  numbers[0] = numbers[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  if (numbers.length === 2) numbers[1] = numbers[1]?.substring(0, tofixed);
  return numbers[1] > 0 ? numbers.join('.') : numbers[0];
};

export const walletAddressSlicer = (wallet) => {
  if (typeof wallet === 'string') {
    return (
      wallet.substring(0, 5) +
      '...' +
      wallet.substring(wallet.length - 5, wallet.length)
    );
  }
};

export const toPascalCase = (str) =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
    .join(' ');

export const timeDifference = (timestamp) => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;
  const current = new Date();
  const previous = timestamp * 1000;

  var elapsed = current - previous;
  if (Number(elapsed) < 0) return '-';
  if (elapsed < msPerMinute) {
    return (
      Math.round(elapsed / 1000) +
      ` ${Math.round(elapsed / 1000) === 1 ? 'second' : 'seconds'} ago`
    );
  } else if (elapsed < msPerHour) {
    return (
      Math.round(elapsed / msPerMinute) +
      ` ${Math.round(elapsed / msPerMinute) === 1 ? 'minute' : 'minutes'} ago`
    );
  } else if (elapsed < msPerDay) {
    return (
      Math.round(elapsed / msPerHour) +
      ` ${Math.round(elapsed / msPerHour) === 1 ? 'hour' : 'hours'} ago`
    );
  } else if (elapsed < msPerMonth) {
    return (
      Math.round(elapsed / msPerDay) +
      ` ${Math.round(elapsed / msPerDay) === 1 ? 'day' : 'days'} ago`
    );
  } else if (elapsed < msPerYear) {
    return (
      Math.round(elapsed / msPerMonth) +
      ` ${Math.round(elapsed / msPerMonth) === 1 ? 'month' : 'months'} ago`
    );
  } else {
    return (
      Math.round(elapsed / msPerYear) +
      ` ${Math.round(elapsed / msPerYear) === 1 ? 'year' : 'years'} ago`
    );
  }
};

export const timestampToLocalHour = (timestamp) => {
  let date = new Date(Number(timestamp) * 1000);
  let hour = (date.getHours() < 10 ? '0' : '') + date.getHours();
  let minute = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  return `${hour}:${minute}`;
};

export const timestampToLocalHour2 = (timestamp) => {
  let date = new Date(Number(timestamp));
  let hour = (date.getHours() < 10 ? '0' : '') + date.getHours();
  let minute = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  return `${hour}:${minute}`;
};

export const timestampToLocalDaily = (timestamp) => {
  let date = new Date(Number(timestamp) * 1000);
  let day = (date.getDate() < 10 ? '0' : '') + date.getDate();
  let month = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1);
  let year = (date.getFullYear() < 10 ? '0' : '') + date.getFullYear();
  return `${year}/${month}/${day}`;
};

export const isMatching = (inputString, pattern) => {
  const regex = new RegExp(pattern);
  return regex.test(inputString);
};


export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
