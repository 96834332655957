import React, { useContext, useEffect, useRef, useState } from 'react';
import Pagination from 'react-responsive-pagination';
import { useNavigate } from 'react-router-dom';
import PrivateContext from '../../context/private/privateContext';
import WalletContext from '../../context/private/walletContext';
import customArrowIcon from '../../assets/icons/custom-arrow.svg';
import { Container, Flex } from '../../styles/Styles';

import modalImage from '../../assets/images/diamond.png';

import {
  numberWithCommas,
  timestampToLocalDaily,
  timestampToLocalHour
} from '../../utils/tools';
import Header from '../Header';
import Layout from '../layout/Index';
import ModalDeposit from './modal/deposit';
import { toast } from 'react-hot-toast';
import config from '../../config';
import NotFoundImage from './../../assets/images/not-found.png';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import dictionary from '../../locals/my-wallet.json';
import { ModalMethods, SuccessModal } from '../Modal/Success';
import { IslandWithdrawalModal } from '../Modal/IslandWithdrawal';
import { MyWalletDepositModal } from '../Modal/Deposit/indexddd';
import { TransferModal } from '../Modal/Transfer';
import { WithdrawModal } from '../Modal/Withdraw';
import AccountContext from '../../context/private/accountContext';
import { TransferToIslandModal } from '../Modal/TransferToIsland';
import ModalAgreement from '../DiamondBalance/modal/ModalAgreement';
import Modal from '../DiamondBalance/modal/Modal';
const LoadingAnimation = () => {
  return (
    <Flex x="center" y="center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={100}
        height={100}
        viewBox="0 0 100 100"
        overflow="visible"
        fill="#153458"
      >
        <defs>
          {' '}
          <circle
            id="spinner"
            r={4}
            cx={50}
            cy={50}
            transform="translate(0 -30)"
          />{' '}
        </defs>{' '}
        <use xlinkHref="#spinner" transform="rotate(0 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(30 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.08s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.08s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(60 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.16s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.16s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(90 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.24s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.24s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(120 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.32s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.32s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(150 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.4s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.4s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(180 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.48s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.48s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(210 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.56s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.56s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(240 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.64s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.64s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(270 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.72s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.72s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(300 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.8s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.8s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>
        <use xlinkHref="#spinner" transform="rotate(330 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.88s"
            repeatCount="indefinite"
          />{' '}
          <animateTransform
            attributeName="transform"
            type="translate"
            additive="sum"
            dur="1s"
            begin="0.88s"
            repeatCount="indefinite"
            from="0 0"
            to={10}
          />{' '}
        </use>{' '}
      </svg>
    </Flex>
  );
};

const dateFormat = (time: string) => {
  const date = new Date(time);
  return (
    date.getFullYear() +
    '/' +
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '/' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate())
  );
};

const CryptoDetails = ({
  info,
  detail,
  isSpecial = false
}: {
  info: any;
  detail: any;
  isSpecial: boolean;
}) => {
  return (
    <div className="wall-row-sec">
      {!isSpecial ? (
        <div className="wall-row-sec-flex">
          <div className="wall-row-sec-flex-grid">
            <div className="wall-row-sec-row">
              <div className="wall-row-sec-title">
                {dictionary['MY-WALLET-title87']}
              </div>
              <div className="wall-row-sec-title">{info?.transaction_hash}</div>
            </div>
            {info?.transfer_date ? (
              <div className="wall-row-sec-row">
                <div className="wall-row-sec-title">
                  {dictionary['MY-WALLET-title88']}
                </div>
                <div className="wall-row-sec-title">
                  {dateFormat(info?.transfer_date)}
                </div>
              </div>
            ) : null}
            {info?.clearing_date ? (
              <div className="wall-row-sec-row">
                <div className="wall-row-sec-title">Expected Clearing Date</div>
                <div className="wall-row-sec-title">
                  {dateFormat(info?.clearing_date)}
                </div>
              </div>
            ) : null}
            {info?.hash_id ? (
              <div className="wall-row-sec-row">
                <div className="wall-row-sec-title">Tx ID</div>
                <div className="wall-row-sec-title">
                  {info?.hash_id
                    ? `#${String(info?.hash_id).toUpperCase()}`
                    : '◾️◾️◾️'}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <>
          <div className="wall-row-sec-flex">
            <div className="wall-row-sec-flex-grid">
              {info?.hash_id ? (
                <div className="wall-row-sec-row">
                  <div className="wall-row-sec-title">Tx ID</div>
                  <div className="wall-row-sec-title">
                    {info?.hash_id
                      ? `#${String(info?.hash_id).toUpperCase()}`
                      : '◾️◾️◾️'}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {info?.type == 2 ? (
            <>
              {info?.paidAmount ? (
                <div className="wall-row-sec-flex">
                  <div className="wall-row-sec-flex-grid">
                    {info?.paidAmount ? (
                      <div className="wall-row-sec-row">
                        <div className="wall-row-sec-title">Paid Amount</div>
                        <div className="wall-row-sec-title">
                          {info?.paidAmount
                            ? `$${numberWithCommas(info?.paidAmount)}`
                            : '◾️◾️◾️'}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {info?.PayedMonth ? (
                <div className="wall-row-sec-flex">
                  <div className="wall-row-sec-flex-grid">
                    {info?.PayedMonth ? (
                      <div className="wall-row-sec-row">
                        <div className="wall-row-sec-title">Paid Months</div>
                        <div className="wall-row-sec-title">
                          {info?.PayedMonth
                            ? `${numberWithCommas(info?.PayedMonth)}`
                            : '◾️◾️◾️'}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {info?.remainAmount ? (
                <div className="wall-row-sec-flex">
                  <div className="wall-row-sec-flex-grid">
                    {info?.remainAmount ? (
                      <div className="wall-row-sec-row">
                        <div className="wall-row-sec-title">Remain Amount</div>
                        <div className="wall-row-sec-title">
                          {info?.remainAmount
                            ? `$${numberWithCommas(info?.remainAmount)}`
                            : '◾️◾️◾️'}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {info?.remainMonth ? (
                <div className="wall-row-sec-flex">
                  <div className="wall-row-sec-flex-grid">
                    {info?.remainMonth ? (
                      <div className="wall-row-sec-row">
                        <div className="wall-row-sec-title">
                          Remaining Months
                        </div>
                        <div className="wall-row-sec-title">
                          {info?.remainMonth
                            ? `${numberWithCommas(info?.remainMonth)}`
                            : '◾️◾️◾️'}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </>
      )}
      {info?.request_description ? (
        <div>
          <div className="wall-row-des">
            Requested {dictionary['MY-WALLET-title89']}
          </div>
          <div className="wall-row-det">{info?.request_description}</div>
        </div>
      ) : null}
      {info?.status === 2 && (
        <div>
          <div className="wall-row-des">
            Rejected {dictionary['MY-WALLET-title89']}
          </div>
          <div className="wall-row-det">{info?.description}</div>
        </div>
      )}
      {info?.status === 1 && info?.acceptDescription && (
        <div>
          <div className="wall-row-second-des">
            Accepted {dictionary['MY-WALLET-title101']}
          </div>
          <div className="wall-row-second-det">{info?.acceptDescription}</div>
        </div>
      )}
    </div>
  );
};
const WireDetails = ({ info, detail }: { info: any; detail: any }) => {
  const bankTypes = [
    'Not Chosen',
    'IMBL',
    'TCC',
    'Caye Bank',
    'Chase Bank',
    'Hamilton Bank'
  ];
  return (
    <div className="wall-row-second">
      <div>
        <div className="wall-row-second-parent">
          <div className="wall-row-second-combo">
            <div className="wall-row-sec-title">
              {dictionary['MY-WALLET-title90']}
            </div>
            <div className="wall-row-second-val">{info?.account_number}</div>
          </div>
          <div className="wall-row-second-combo">
            <div className="wall-row-sec-title">
              {dictionary['MY-WALLET-title91']}
            </div>
            <div className="wall-row-second-val">{info?.account_name}</div>
          </div>
          <div className="wall-row-second-combo">
            <div className="wall-row-sec-title">
              {dictionary['MY-WALLET-title92']}
            </div>
            <div className="wall-row-second-val">
              {info?.institution_number}
            </div>
          </div>
          <div className="wall-row-second-combo">
            <div className="wall-row-sec-title">
              {dictionary['MY-WALLET-title93']}
            </div>
            <div className="wall-row-second-val">
              {info?.institution_number}
            </div>
          </div>
          <div className="wall-row-second-combo">
            <div className="wall-row-sec-title">
              {dictionary['MY-WALLET-title94']}
            </div>
            <div className="wall-row-second-val">{info?.iban}</div>
          </div>
          <div className="wall-row-second-combo">
            <div className="wall-row-sec-title">
              {dictionary['MY-WALLET-title95']}
            </div>
            <div className="wall-row-second-val-more">
              {info?.recipient_address}
            </div>
          </div>
          <div className="wall-row-second-combo">
            <div className="wall-row-sec-title">
              {dictionary['MY-WALLET-title96']}
            </div>
            <div className="wall-row-second-val">{info?.swift_code}</div>
          </div>
          <div className="wall-row-second-combo">
            <div className="wall-row-sec-title">
              {dictionary['MY-WALLET-title125']}
            </div>
            <div className="wall-row-second-val">
              {bankTypes[info?.bank_type]}
            </div>
          </div>
          <div className="wall-row-second-combo">
            <div className="wall-row-sec-title">
              {dictionary['MY-WALLET-title97']}
            </div>
            <div className="wall-row-second-val">{info?.bank_name}</div>
          </div>
          {/* <div className="wall-row-second-combo">
            <div className="wall-row-sec-title">
              {dictionary['MY-WALLET-title123']}
            </div>
            <div className="wall-row-second-val">
              {info?.transaction_date
                ? dateFormat(info?.transaction_date)
                : info?.transfer_date
                  ? dateFormat(info?.transfer_date)
                  : '---'}
            </div>
          </div> */}
          <div className="wall-row-second-combo">
            <div className="wall-row-sec-title">Expected Clearing Date</div>
            <div className="wall-row-second-val">
              {info?.clearing_date
                ? dateFormat(info?.clearing_date)
                : dateFormat(info?.transfer_date)}
            </div>
          </div>
          {info?.hash_id ? (
            <div className="wall-row-second-combo">
              <div className="wall-row-sec-title">Tx ID</div>
              <div className="wall-row-second-val">
                {info?.hash_id
                  ? `#${String(info?.hash_id).toUpperCase()}`
                  : '◾️◾️◾️'}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="wall-row-second-pdf">
        <Flex gap={1} tabletM={'col'}>
          {info && info?.transaction_document && (
            <div
              className="wall-row-second-pdf-div"
              onClick={() =>
                window.open(config.server + info?.transaction_document)
              }
            >
              <img src="/images/Adob.svg" alt="pdf" />
              <span>{dictionary['MY-WALLET-title100']}</span>
            </div>
          )}
          {info && info?.transaction_document_2 && (
            <div
              className="wall-row-second-pdf-div"
              onClick={() =>
                window.open(config.server + info?.transaction_document)
              }
            >
              <img src="/images/Adob.svg" alt="pdf" />
              <span>{dictionary['MY-WALLET-title133']} 1</span>
            </div>
          )}
          {info && info?.transaction_document_3 && (
            <div
              className="wall-row-second-pdf-div"
              onClick={() =>
                window.open(config.server + info?.transaction_document)
              }
            >
              <img src="/images/Adob.svg" alt="pdf" />
              <span>{dictionary['MY-WALLET-title133']} 2</span>
            </div>
          )}
        </Flex>
        {info?.request_description ? (
          <div>
            <div className="wall-row-second-des">
              Requested {dictionary['MY-WALLET-title101']}
            </div>
            <div className="wall-row-second-det">
              {info?.request_description}
            </div>
          </div>
        ) : null}
        {info?.status === 2 && (
          <div>
            <div className="wall-row-second-des">
              Rejected {dictionary['MY-WALLET-title101']}
            </div>
            <div className="wall-row-second-det">{info?.description}</div>
          </div>
        )}
        {info?.status === 1 && info?.acceptDescription && (
          <div>
            <div className="wall-row-second-des">
              Accepted {dictionary['MY-WALLET-title101']}
            </div>
            <div className="wall-row-second-det">{info?.acceptDescription}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const PaymentItem = ({
  info,
  detail,
  type
}: {
  info: any;
  detail?: any;
  type: string;
}): JSX.Element => {
  const { setUpdateTimer } = useContext(PrivateContext);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setUpdateTimer(+new Date());
  }, [isOpen]);

  const { transactions, loadTransaction } = useContext(WalletContext);

  return (
    <div className={isOpen ? 'wall-row' : 'wall-row closed'}>
      <div className="wall-row-f">
        <div className="wall-row-f-grid">
          <div className="wall-row-f-income">
            {info?.type === 1 && (
              <img src="/images/income.svg" alt="income.svg" />
            )}
            {info?.type === 2 && (
              <img src="/images/outcome.svg" alt="outcome.svg" />
            )}
          </div>
          <div className="wall-row-f-row">
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title102']}
              </div>
              <div className="wall-row-f-row-title-val">
                {info?.amount || Number(info?.amount) === 0
                  ? `$${numberWithCommas(info?.amount, 2)}`
                  : 'Loading...'}
              </div>
            </div>
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title103']}
              </div>
              <div className="wall-row-f-row-title-val">
                {type === 'internal'
                  ? 'Internal Tx'
                  : type === 'Wire'
                  ? 'Bank'
                  : type}
              </div>
            </div>
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title104']}
              </div>
              <div className="wall-row-f-row-title-val small">
                {(info?.type === 1 &&
                  ((info?.payment_type === 6 &&
                    'New Deposit - 100% Treasury (Bank Wire)') ||
                    (info?.payment_type === 8 &&
                      'New Deposit - 100% Treasury (Crypto)') ||
                    (info?.payment_type === 7 &&
                      'New Deposit - Diversification (Split Bank Wire)') ||
                    (info?.payment_type === 9 &&
                      'New Deposit - Diversification (Split Crypto)'))) ||
                  'Deposit'}
                {info?.type === 2 &&
                  `Withdraw ${
                    +info.payment_balance
                      ? 'from Gratitude'
                      : 'from BOS Balance'
                  }`}
                {info?.type === 1 && info?.payment_type === 3 && 'Payment'}{' '}
              </div>
            </div>
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title105']}
              </div>
              <div className="wall-row-f-row-title-val small">
                {info?.fee_amount
                  ? `$${numberWithCommas(info?.fee_amount, 2)}`
                  : '$0'}
              </div>
            </div>
            {/* dateFormat(info?.clearing_date) */}
            {info?.type === 1 && (
              <div className="item-col">
                <div className="wall-row-f-row-title">
                  {info?.type === 1 &&
                  info?.payment_type !== 3 &&
                  type === 'Wire'
                    ? 'Clearing Date'
                    : 'TX Date'}
                </div>
                <div className="wall-row-f-row-title-val small">
                  {/* {info?.created_at ? dateFormat(info?.created_at) : <div className='skeleton-element-item skeleton-box'></div>} */}
                  {info?.type === 1 &&
                  info?.payment_type !== 3 &&
                  type === 'Wire'
                    ? dateFormat(
                        info?.clearing_date
                          ? info?.clearing_date
                          : info?.transfer_date
                      )
                    : info?.transfer_date
                    ? `${timestampToLocalDaily(
                        Number(new Date(info?.transfer_date)) / 1000
                      )}, ${timestampToLocalHour(
                        Number(new Date(info?.transfer_date)) / 1000
                      )}`
                    : '◾️◾️◾️'}
                </div>
              </div>
            )}
            {info?.type === 2 && (
              <div className="item-col">
                <div className="wall-row-f-row-title">Requested Date</div>
                <div className="wall-row-f-row-title-val small">
                  {info?.request_date
                    ? `${timestampToLocalDaily(
                        Number(new Date(info?.request_date)) / 1000
                      )}, ${timestampToLocalHour(
                        Number(new Date(info?.request_date)) / 1000
                      )}`
                    : '◾️◾️◾️'}
                  {/* {info?.created_at ? dateFormat(info?.created_at) : 'Loading...'} */}
                </div>
              </div>
            )}
            {info && info?.hash_id ? (
              <div className="item-col">
                <div className="wall-row-f-row-title">Tx ID</div>
                <div className="wall-row-f-row-title-val small">
                  {info?.hash_id
                    ? `#${String(info?.hash_id).toUpperCase()}`
                    : '◾️◾️◾️'}
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="wall-row-f-r">
          <div
            className="wall-row-f-r-suc"
            style={
              info?.status === 0
                ? { color: 'gray' }
                : info?.status === 1
                ? { color: 'green' }
                : info?.status === 2
                ? { color: 'red' }
                : {}
            }
          >
            {info?.status === 0 && 'Pending'}
            {info?.status === 1 && 'Accepted - Done'}
            {info?.status === 2 && 'Rejected'}
            {info?.status === 3 && 'Approve - Waiting'}
            {info?.status === 5 && 'Validation error - Rejected'}
            {info?.status === 6 && 'In queue - Accepted'}
            {info?.status === 7 && 'In queue - Rejected'}
          </div>
          <button
            className={
              type === 'internal'
                ? 'wall-row-f-r-more'
                : 'wall-row-f-r-more pointer'
            }
            onClick={() => (type === 'internal' ? null : setIsOpen(!isOpen))}
          >
            <img
              className={!isOpen ? 'rotate' : ''}
              src="/images/closeVector.svg"
              alt="closeVector"
              style={{
                opacity: type === 'internal' ? 0 : 1
              }}
            />
          </button>
        </div>
      </div>

      {isOpen && type === 'Crypto' && (
        <CryptoDetails info={info} detail={detail} isSpecial={false} />
      )}
      {isOpen && type === 'Wire' && <WireDetails info={info} detail={detail} />}
    </div>
  );
};

const CryptoDetailsNew = ({
  info,
  detail,
  isSpecial = false
}: {
  info: any;
  detail: any;
  isSpecial: boolean;
}) => {
  const { cancelWithdrawHandle } = useContext(WalletContext);

  const [modalSelect, setModalSelect] = useState(false);
  return (
    <>
      {modalSelect && (
        <Flex x="center">
          <div
            className="mod"
            style={{
              alignItems: 'center'
            }}
          >
            <div className="mod-border">
              <div className="mod-box">
                <p className="mod-box-p">{dictionary['MY-WALLET-title139']}</p>
                <button
                  className="mod-box-btn"
                  onClick={() => setModalSelect(false)}
                >
                  X
                </button>
              </div>
              <div style={{ marginRight: 10, marginLeft: 10 }}></div>
              <div className="mod-inside">
                <div className="mod-inside-body">
                  <b>{dictionary['MY-WALLET-title138']}</b>
                </div>
                <Flex gap={0.8} x="center">
                  <button
                    className="mod-inside-body-btn pointer"
                    onClick={() => setModalSelect(false)}
                  >
                    {dictionary['MY-WALLET-title130']}
                  </button>
                  <button
                    className="mod-inside-body-btn pointer"
                    onClick={() => {
                      cancelWithdrawHandle(info?.id);
                      setModalSelect(false);
                    }}
                  >
                    Submit
                  </button>
                </Flex>
              </div>
            </div>

            <div
              className="blur-bg"
              onClick={() => setModalSelect(false)}
            ></div>
          </div>
        </Flex>
      )}
      <div className="wall-row-sec">
        {!isSpecial ? (
          <div className="wall-row-sec-flex">
            <div className="wall-row-sec-flex-grid">
              <div className="wall-row-sec-row">
                <div className="wall-row-sec-title">
                  {dictionary['MY-WALLET-title87']}
                </div>
                <div className="wall-row-sec-title">
                  {info?.transaction_hash}
                </div>
              </div>
              {info?.transfer_date ? (
                <div className="wall-row-sec-row">
                  <div className="wall-row-sec-title">
                    {dictionary['MY-WALLET-title88']}
                  </div>
                  <div className="wall-row-sec-title">
                    {dateFormat(info?.transfer_date)}
                  </div>
                </div>
              ) : null}
              {info?.hash_id ? (
                <div className="wall-row-sec-row">
                  <div className="wall-row-sec-title">Tx ID</div>
                  <div className="wall-row-sec-title">
                    {info?.hash_id
                      ? `#${String(info?.hash_id).toUpperCase()}`
                      : '◾️◾️◾️'}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <>
            <div className="wall-row-sec-flex">
              <div className="wall-row-sec-flex-grid">
                {info?.hash_id ? (
                  <div className="wall-row-sec-row">
                    <div className="wall-row-sec-title">Tx ID</div>
                    <div className="wall-row-sec-title">
                      {info?.hash_id
                        ? `#${String(info?.hash_id).toUpperCase()}`
                        : '◾️◾️◾️'}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {info?.type == 2 ? (
              <>
                {info?.paidAmount ? (
                  <div className="wall-row-sec-flex">
                    <div className="wall-row-sec-flex-grid">
                      {info?.paidAmount ? (
                        <div className="wall-row-sec-row">
                          <div className="wall-row-sec-title">Paid Amount</div>
                          <div className="wall-row-sec-title">
                            {info?.paidAmount
                              ? `$${numberWithCommas(info?.paidAmount)}`
                              : '◾️◾️◾️'}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {info?.PayedMonth ? (
                  <div className="wall-row-sec-flex">
                    <div className="wall-row-sec-flex-grid">
                      {info?.PayedMonth ? (
                        <div className="wall-row-sec-row">
                          <div className="wall-row-sec-title">Paid Months</div>
                          <div className="wall-row-sec-title">
                            {info?.PayedMonth
                              ? `${numberWithCommas(info?.PayedMonth)}`
                              : '◾️◾️◾️'}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {info?.remainAmount ? (
                  <div className="wall-row-sec-flex">
                    <div className="wall-row-sec-flex-grid">
                      {info?.remainAmount ? (
                        <div className="wall-row-sec-row">
                          <div className="wall-row-sec-title">
                            Remain Amount
                          </div>
                          <div className="wall-row-sec-title">
                            {info?.remainAmount
                              ? `$${numberWithCommas(info?.remainAmount)}`
                              : '◾️◾️◾️'}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {info?.remainMonth ? (
                  <div className="wall-row-sec-flex">
                    <div className="wall-row-sec-flex-grid">
                      {info?.remainMonth ? (
                        <div className="wall-row-sec-row">
                          <div className="wall-row-sec-title">
                            Remaining Months
                          </div>
                          <div className="wall-row-sec-title">
                            {info?.remainMonth
                              ? `${numberWithCommas(info?.remainMonth)}`
                              : '◾️◾️◾️'}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
          </>
        )}
        <div>
          <div className="wall-row-des">{dictionary['MY-WALLET-title89']}</div>
          <div className="wall-row-det">{info?.request_description}</div>
          {info?.status === 0 && (
            <button
              className="wall-body-post-mid-btn btn-cancel"
              onClick={() => {
                setModalSelect(true);
                window.scrollTo(0, 0);
              }}
            >
              Cancel
            </button>
          )}
        </div>
        {info?.status === 2 && (
          <div>
            <div className="wall-row-des" style={{ color: 'red' }}>
              {dictionary['MY-WALLET-title117']}
            </div>
            <div className="wall-row-det" style={{ color: 'red' }}>
              {info?.description}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
const WireDetailsNew = ({ info, detail }: { info: any; detail: any }) => {
  const { cancelWithdrawHandle } = useContext(WalletContext);
  const bankTypes = [
    'Not Chosen',
    'IMBL',
    'TCC',
    'Caye Bank',
    'Chase Bank',
    'Hamilton Bank'
  ];
  const [modalSelect, setModalSelect] = useState(false);
  return (
    <>
      {modalSelect && (
        <Flex x="center">
          <div
            className="mod"
            style={{
              alignItems: 'center'
            }}
          >
            <div className="mod-border">
              <div className="mod-box">
                <p className="mod-box-p">{dictionary['MY-WALLET-title139']}</p>
                <button
                  className="mod-box-btn"
                  onClick={() => setModalSelect(false)}
                >
                  X
                </button>
              </div>
              <div style={{ marginRight: 10, marginLeft: 10 }}></div>
              <div className="mod-inside">
                <div className="mod-inside-body">
                  <b>{dictionary['MY-WALLET-title138']}</b>
                </div>
                <Flex gap={0.8} x="center">
                  <button
                    className="mod-inside-body-btn pointer"
                    onClick={() => setModalSelect(false)}
                  >
                    {dictionary['MY-WALLET-title130']}
                  </button>
                  <button
                    className="mod-inside-body-btn pointer"
                    onClick={() => {
                      cancelWithdrawHandle(info?.id);
                      setModalSelect(false);
                    }}
                  >
                    Submit
                  </button>
                </Flex>
              </div>
            </div>

            <div
              className="blur-bg"
              onClick={() => setModalSelect(false)}
            ></div>
          </div>
        </Flex>
      )}

      <div className="wall-row-second">
        <div>
          <div className="wall-row-second-parent">
            <div className="wall-row-second-combo">
              <div className="wall-row-sec-title">
                {dictionary['MY-WALLET-title90']}
              </div>
              <div className="wall-row-second-val">{info?.account_number}</div>
            </div>
            <div className="wall-row-second-combo">
              <div className="wall-row-sec-title">
                {dictionary['MY-WALLET-title91']}
              </div>
              <div className="wall-row-second-val">{info?.account_name}</div>
            </div>
            <div className="wall-row-second-combo">
              <div className="wall-row-sec-title">
                {dictionary['MY-WALLET-title92']}
              </div>
              <div className="wall-row-second-val">
                {info?.institution_number}
              </div>
            </div>
            <div className="wall-row-second-combo">
              <div className="wall-row-sec-title">
                {dictionary['MY-WALLET-title93']}
              </div>
              <div className="wall-row-second-val">
                {info?.institution_number}
              </div>
            </div>
            <div className="wall-row-second-combo">
              <div className="wall-row-sec-title">
                {dictionary['MY-WALLET-title94']}
              </div>
              <div className="wall-row-second-val">{info?.iban}</div>
            </div>
            <div className="wall-row-second-combo">
              <div className="wall-row-sec-title">
                {dictionary['MY-WALLET-title95']}
              </div>
              <div className="wall-row-second-val-more">
                {info?.recipient_address}
              </div>
            </div>
            <div className="wall-row-second-combo">
              <div className="wall-row-sec-title">
                {dictionary['MY-WALLET-title96']}
              </div>
              <div className="wall-row-second-val">{info?.swift_code}</div>
            </div>
            <div className="wall-row-second-combo">
              <div className="wall-row-sec-title">
                {dictionary['MY-WALLET-title125']}
              </div>
              <div className="wall-row-second-val">
                {bankTypes[info?.bank_type]}
              </div>
            </div>
            <div className="wall-row-second-combo">
              <div className="wall-row-sec-title">
                {dictionary['MY-WALLET-title97']}
              </div>
              <div className="wall-row-second-val">{info?.bank_name}</div>
            </div>
            {/* <div className="wall-row-second-combo">
              <div className="wall-row-sec-title">
                {dictionary['MY-WALLET-title123']}
              </div>
              <div className="wall-row-second-val">
                {info?.transaction_date
                  ? dateFormat(info?.transaction_date)
                  : info?.transfer_date
                    ? dateFormat(info?.transfer_date)
                    : '---'}
              </div>
            </div> */}
            {info?.hash_id ? (
              <div className="wall-row-second-combo">
                <div className="wall-row-sec-title">Tx ID</div>
                <div className="wall-row-second-val">
                  {info?.hash_id
                    ? `#${String(info?.hash_id).toUpperCase()}`
                    : '◾️◾️◾️'}
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="wall-row-second-pdf">
          <Flex gap={1} tabletM={'col'}>
            {info && info?.transaction_document && (
              <div
                className="wall-row-second-pdf-div"
                onClick={() =>
                  window.open(config.server + info?.transaction_document)
                }
              >
                <img src="/images/Adob.svg" alt="pdf" />
                <span>{dictionary['MY-WALLET-title100']}</span>
              </div>
            )}
            {info && info?.transaction_document_2 && (
              <div
                className="wall-row-second-pdf-div"
                onClick={() =>
                  window.open(config.server + info?.transaction_document)
                }
              >
                <img src="/images/Adob.svg" alt="pdf" />
                <span>{dictionary['MY-WALLET-title133']} 1</span>
              </div>
            )}
            {info && info?.transaction_document_3 && (
              <div
                className="wall-row-second-pdf-div"
                onClick={() =>
                  window.open(config.server + info?.transaction_document)
                }
              >
                <img src="/images/Adob.svg" alt="pdf" />
                <span>{dictionary['MY-WALLET-title133']} 2</span>
              </div>
            )}
          </Flex>
          <div>
            <div className="wall-row-second-des">
              {dictionary['MY-WALLET-title101']}
            </div>
            <div className="wall-row-second-det">
              {info?.request_description}
            </div>
          </div>
        </div>

        {info?.status === 0 && (
          <button
            className="wall-body-post-mid-btn btn-cancel"
            onClick={() => {
              setModalSelect(true);
              window.scrollTo(0, 0);
            }}
          >
            Cancel
          </button>
        )}
      </div>
    </>
  );
};

const PaymentItemNew = ({
  info,
  detail,
  type
}: {
  info: any;
  detail?: any;
  type: string;
}): JSX.Element => {
  const { setUpdateTimer } = useContext(PrivateContext);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setUpdateTimer(+new Date());
  }, [isOpen]);

  const { transactions, loadTransaction } = useContext(WalletContext);

  return (
    <div className={isOpen ? 'wall-row' : 'wall-row closed'}>
      <div className="wall-row-f">
        <div className="wall-row-f-grid">
          <div className="wall-row-f-income">
            {info?.type === 1 && (
              <img src="/images/income.svg" alt="income.svg" />
            )}
            {info?.type === 2 && (
              <img src="/images/outcome.svg" alt="outcome.svg" />
            )}
          </div>
          <div className="wall-row-f-row">
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title102']}
              </div>
              <div className="wall-row-f-row-title-val">
                {info?.amount || Number(info?.amount) === 0
                  ? `$${numberWithCommas(info?.amount, 2)}`
                  : 'Loading...'}
              </div>
            </div>
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title103']}
              </div>
              <div className="wall-row-f-row-title-val">
                {type === 'internal'
                  ? 'Internal Tx'
                  : type === 'Wire'
                  ? 'Bank'
                  : type}
              </div>
            </div>
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title104']}
              </div>
              <div className="wall-row-f-row-title-val small">
                {info?.type === 1 &&
                  ((info?.payment_type === 6 &&
                    'New Deposit - 100% Treasury (Bank Wire)') ||
                    (info?.payment_type === 8 &&
                      'New Deposit - 100% Treasury (Crypto)') ||
                    (info?.payment_type === 7 &&
                      'New Deposit - Diversification (Split Bank Wire)') ||
                    (info?.payment_type === 9 &&
                      'New Deposit - Diversification (Split Crypto)') ||
                    'Deposit')}
                {info?.type === 2 &&
                  `Withdraw ${
                    +info.payment_balance
                      ? 'from Gratitude'
                      : 'from BOS Balance'
                  }`}
                {info?.type === 1 && info?.payment_type === 3 && 'Payment'}{' '}
              </div>
            </div>
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title105']}
              </div>
              <div className="wall-row-f-row-title-val small">
                {info?.fee_amount
                  ? `$${numberWithCommas(info?.fee_amount, 2)}`
                  : '$0'}
              </div>
            </div>
            {info?.type === 1 && (
              <div className="item-col">
                <div className="wall-row-f-row-title">
                  {info?.type === 1 &&
                  info?.payment_type !== 3 &&
                  type === 'Wire'
                    ? 'Clearing Date'
                    : 'TX Date'}
                </div>
                <div className="wall-row-f-row-title-val small">
                  {/* {info?.created_at ? dateFormat(info?.created_at) : <div className='skeleton-element-item skeleton-box'></div>} */}
                  {info?.transfer_date
                    ? `${timestampToLocalDaily(
                        Number(new Date(info?.transfer_date)) / 1000
                      )}, ${timestampToLocalHour(
                        Number(new Date(info?.transfer_date)) / 1000
                      )}`
                    : '◾️◾️◾️'}
                </div>
              </div>
            )}
            {info?.type === 2 && (
              <div className="item-col">
                <div className="wall-row-f-row-title">Requested Date</div>
                <div className="wall-row-f-row-title-val small">
                  {info?.request_date
                    ? `${timestampToLocalDaily(
                        Number(new Date(info?.request_date)) / 1000
                      )}, ${timestampToLocalHour(
                        Number(new Date(info?.request_date)) / 1000
                      )}`
                    : '◾️◾️◾️'}
                  {/* {info?.created_at ? dateFormat(info?.created_at) : 'Loading...'} */}
                </div>
              </div>
            )}
            {info && info?.hash_id ? (
              <div className="item-col">
                <div className="wall-row-f-row-title">Tx ID</div>
                <div className="wall-row-f-row-title-val small">
                  {info?.hash_id
                    ? `#${String(info?.hash_id).toUpperCase()}`
                    : '◾️◾️◾️'}
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="wall-row-f-r">
          <div
            className="wall-row-f-r-suc"
            style={
              info?.status === 0
                ? { color: 'gray' }
                : info?.status === 1
                ? { color: 'green' }
                : info?.status === 2
                ? { color: 'red' }
                : info?.status === 4
                ? { color: 'red' }
                : {}
            }
          >
            {info?.status === 0 && 'Pending'}
            {info?.status === 1 && 'Accepted - Done'}
            {info?.status === 2 && 'Rejected'}
            {info?.status === 3 && 'Approve - Waiting'}
            {info?.status === 4 && 'Canceled by user'}

            {info?.status === 5 && 'Validation error - Rejected'}
            {info?.status === 6 && 'In queue - Accepted'}
            {info?.status === 7 && 'In queue - Rejected'}
          </div>
          <button
            className={
              type === 'internal'
                ? 'wall-row-f-r-more'
                : 'wall-row-f-r-more pointer'
            }
            onClick={() => (type === 'internal' ? null : setIsOpen(!isOpen))}
          >
            <img
              className={!isOpen ? 'rotate' : ''}
              src="/images/closeVector.svg"
              alt="closeVector"
              style={{
                opacity: type === 'internal' ? 0 : 1
              }}
            />
          </button>
        </div>
      </div>

      {isOpen && type === 'Crypto' && (
        <CryptoDetailsNew info={info} detail={detail} isSpecial={false} />
      )}
      {isOpen && type === 'Wire' && (
        <WireDetailsNew info={info} detail={detail} />
      )}
    </div>
  );
};

const InternalPaymentItem = ({
  info,
  detail,
  type
}: {
  info: any;
  detail?: any;
  type: any;
}): JSX.Element => {
  const { setUpdateTimer } = useContext(PrivateContext);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setUpdateTimer(+new Date());
  }, [isOpen]);

  const { transactions, loadTransaction } = useContext(WalletContext);

  const methodTitle = [
    'Participate',
    'Topup',
    'Claim',
    'Force Withdrawal',
    '',
    'Force Withdrawal by Admin',
    'Withdraw All Upon Completion',
    'Transfer'
  ];

  return (
    <div className={isOpen ? 'wall-row' : 'wall-row closed'}>
      <div className="wall-row-f">
        <div className="wall-row-f-grid w-full">
          <div className="flex w-full justify-between">
            <div className="wall-row-f-income">
              {type == 0 && <img src="/images/outcome.svg" alt="outcome.svg" />}
              {type == 1 && <img src="/images/outcome.svg" alt="outcome.svg" />}
              {type == 2 && <img src="/images/income.svg" alt="income.svg" />}
              {type == 3 && <img src="/images/income.svg" alt="income.svg" />}
              {type == 4 && <img src="/images/income.svg" alt="income.svg" />}
              {type == 5 && <img src="/images/income.svg" alt="income.svg" />}
              {type == 6 && <img src="/images/income.svg" alt="income.svg" />}
              {type == 7 && <img src="/images/outcome.svg" alt="outcome.svg" />}
            </div>

            <div className="wall-row-f-row">
              <div className="item-col">
                <div className="wall-row-f-row-title">
                  {dictionary['MY-WALLET-title102']}
                </div>
                <div className="wall-row-f-row-title-val">
                  {info?.amount || Number(info?.amount) === 0
                    ? `$${numberWithCommas(info?.amount, 2)}`
                    : 'Loading...'}
                </div>
              </div>
              <div className="item-col">
                <div className="wall-row-f-row-title">
                  {dictionary['MY-WALLET-title103']}
                </div>
                <div className="wall-row-f-row-title-val">
                  {dictionary['MY-WALLET-title106']}
                </div>
              </div>
              <div className="item-col">
                <div className="wall-row-f-row-title">
                  {dictionary['MY-WALLET-title104']}
                </div>
                <div className="wall-row-f-row-title-val small">
                  {methodTitle[type]}
                  {type === 5 && info?.is_close === 1 ? ' (Closed)' : null}
                </div>
              </div>
              <div className="item-col">
                <div className="wall-row-f-row-title">
                  {dictionary['MY-WALLET-title118']}
                </div>
                <div className="wall-row-f-row-title-val small">
                  {info?.title && info?.title}
                </div>
              </div>
              <div className="item-col">
                <div className="wall-row-f-row-title">
                  {dictionary['MY-WALLET-title105']}
                </div>
                <div className="wall-row-f-row-title-val small">
                  {info?.fee ? `$${numberWithCommas(info?.fee, 2)}` : '$0'}
                </div>
              </div>
              {info && info?.hash_id ? (
                <div className="item-col">
                  <div className="wall-row-f-row-title">Tx ID</div>
                  <div className="wall-row-f-row-title-val small">
                    {info?.hash_id
                      ? `#${String(info?.hash_id).toUpperCase()}`
                      : '◾️◾️◾️'}
                  </div>
                </div>
              ) : null}
              <div className="item-col">
                <div className="wall-row-f-row-title">
                  {dictionary['MY-WALLET-title112']}
                </div>
                <div className="wall-row-f-row-title-val small">
                  {/* {info?.created_at ? dateFormat(info?.created_at) : <div className='skeleton-element-item skeleton-box'></div>} */}
                  {info?.timestamp
                    ? `${timestampToLocalDaily(
                        Number(new Date(info?.timestamp)) / 1000
                      )}, ${timestampToLocalHour(
                        Number(new Date(info?.timestamp)) / 1000
                      )}`
                    : '◾️◾️◾️'}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="trans-middle ml-auto">
          <div
            className="trans-middle-val"
            style={
              info?.status === 0
                ? { color: 'gray' }
                : info?.status === 1
                ? { color: 'green' }
                : info?.status === 2
                ? { color: 'red' }
                : {}
            }
          >
            {info?.status === 0 && 'Pending'}
            {info?.status === 1 && 'Accepted'}
            {info?.status === 2 && 'Rejected'}
            {info?.status === 3 && 'Confirmed - Waiting'}

            {info?.status === 5 && 'Validation error - Rejected'}
            {info?.status === 6 && 'In queue - Accepted'}
            {info?.status === 7 && 'In queue - Rejected'}
          </div>

          {info?.status === 2 && (
            <button
              className={'wall-row-f-r-more pointer'}
              onClick={() => setIsOpen(!isOpen)}
            >
              <img
                className={!isOpen ? 'rotate' : ''}
                src="/images/closeVector.svg"
                alt="closeVector"
                style={{
                  opacity: type === 'internal' ? 0 : 1
                }}
              />
            </button>
          )}
        </div>
      </div>

      {isOpen && (
        <div className="wall-row-second-det">
          <div className="wall-row-second">
            <div className="wall-row-second-pdf">
              {info?.status === 2 && (
                <div>
                  <div className="wall-row-second-des">Reason</div>
                  <div
                    className="wall-row-second-det"
                    dangerouslySetInnerHTML={{
                      __html: info?.description
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* {isOpen && type === 'Crypto' && (
        <CryptoDetails info={info} detail={detail} />
      )}
      {isOpen && type === 'Wire' && <WireDetails info={info} detail={detail} />} */}
    </div>
  );
};

const PaymentItemNew33 = ({
  info,
  detail,
  type
}: {
  info: any;
  detail?: any;
  type: string;
}): JSX.Element => {
  const { setUpdateTimer } = useContext(PrivateContext);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setUpdateTimer(+new Date());
  }, [isOpen]);

  const DiamondFilterType: { [key: number]: string } = {
    0: 'New Deposit to Treasury Balance',
    1: 'Transfer Main Balance to Treasury Balance',
    2: 'Claim Treasury Balance Interest',
    3: 'Reallocation Treasury Balance',
    4: '',
    5: 'Renew Treasury Balance',
    6: 'Transfer Treasury Balance to Main Balance',
    7: 'Cancelled Treasury Balance back to Main Balance'
  };

  const { transactions, loadTransaction } = useContext(WalletContext);

  return (
    <div className={isOpen ? 'wall-row' : 'wall-row closed'}>
      <div className="wall-row-f">
        <div className="wall-row-f-grid">
          <div className="wall-row-f-income">
            {(info?.type === 0 ||
              info?.type === 1 ||
              info?.type === 3 ||
              info?.type === 5) && (
              <img src="/images/income.svg" alt="income.svg" />
            )}
            {(info?.type === 2 || info?.type === 6) && (
              <img src="/images/outcome.svg" alt="outcome.svg" />
            )}
          </div>
          <div className="wall-row-f-row">
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title102']}
              </div>
              <div className="wall-row-f-row-title-val">
                {info?.amount || Number(info?.amount) === 0
                  ? `$${numberWithCommas(info?.amount, 2)}`
                  : 'Loading...'}
              </div>
            </div>
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title105']}
              </div>
              <div className="wall-row-f-row-title-val small">
                {info?.fee ? `$${numberWithCommas(info?.fee, 2)}` : '$0'}
              </div>
            </div>
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title104']}
              </div>
              <div className="wall-row-f-row-title-val small text-nowrap w-[350px]">
                {info ? DiamondFilterType[info?.type] : null}
              </div>
            </div>
            <div className="item-col">
              <div className="wall-row-f-row-title">Requested Date</div>
              <div className="wall-row-f-row-title-val small">
                {info?.created_at
                  ? `${timestampToLocalDaily(
                      Number(new Date(info?.created_at)) / 1000
                    )}, ${timestampToLocalHour(
                      Number(new Date(info?.created_at)) / 1000
                    )}`
                  : '◾️◾️◾️'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InternalPaymentItemNew = ({
  info,
  detail,
  type
}: {
  info: any;
  detail?: any;
  type: any;
}): JSX.Element => {
  const { setUpdateTimer } = useContext(PrivateContext);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setUpdateTimer(+new Date());
  }, [isOpen]);

  const { transactions, loadTransaction } = useContext(WalletContext);

  const methodTitle = [
    'Participate',
    'Topup',
    'Claim',
    'Force Withdrawal',
    '',
    'Force Withdrawal by Admin',
    'Withdraw All Upon Completion',
    'Transfer'
  ];

  return (
    <div className={isOpen ? 'wall-row' : 'wall-row closed'}>
      <div className="wall-row-f">
        <div className="wall-row-f-grid">
          <div className="wall-row-f-income">
            {type == 0 && <img src="/images/income.svg" alt="outcome.svg" />}
            {type == 1 && <img src="/images/income.svg" alt="outcome.svg" />}
            {type == 2 && <img src="/images/outcome.svg" alt="income.svg" />}
            {type == 3 && <img src="/images/outcome.svg" alt="income.svg" />}
            {type == 4 && <img src="/images/outcome.svg" alt="income.svg" />}
            {type == 5 && <img src="/images/outcome.svg" alt="income.svg" />}
            {type == 6 && <img src="/images/outcome.svg" alt="income.svg" />}
            {type == 7 && <img src="/images/outcome.svg" alt="outcome.svg" />}
          </div>
          <div className="wall-row-f-row">
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title102']}
              </div>
              <div className="wall-row-f-row-title-val">
                {info?.amount || Number(info?.amount) === 0
                  ? `$${numberWithCommas(info?.amount, 2)}`
                  : 'Loading...'}
              </div>
            </div>
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title103']}
              </div>
              <div className="wall-row-f-row-title-val">
                {dictionary['MY-WALLET-title106']}
              </div>
            </div>
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title104']}
              </div>
              <div className="wall-row-f-row-title-val small">
                {methodTitle[type]}
                {type === 5 && info?.is_close === 1 ? ' (Closed)' : null}
              </div>
            </div>
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title118']}
              </div>
              <div className="wall-row-f-row-title-val small">
                {info?.title && info?.title}
              </div>
            </div>
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title105']}
              </div>
              <div className="wall-row-f-row-title-val small">
                {info?.fee ? `$${numberWithCommas(info?.fee, 2)}` : '$0'}
              </div>
            </div>
            {info && info?.hash_id ? (
              <div className="item-col">
                <div className="wall-row-f-row-title">Tx ID</div>
                <div className="wall-row-f-row-title-val small">
                  {info?.hash_id
                    ? `#${String(info?.hash_id).toUpperCase()}`
                    : '◾️◾️◾️'}
                </div>
              </div>
            ) : null}
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title112']}
              </div>
              <div className="wall-row-f-row-title-val small">
                {/* {info?.created_at ? dateFormat(info?.created_at) : <div className='skeleton-element-item skeleton-box'></div>} */}
                {info?.timestamp
                  ? `${timestampToLocalDaily(
                      Number(new Date(info?.timestamp)) / 1000
                    )}, ${timestampToLocalHour(
                      Number(new Date(info?.timestamp)) / 1000
                    )}`
                  : '◾️◾️◾️'}
              </div>
            </div>
          </div>
        </div>
        <div className="wall-row-f-r">
          <div
            className="wall-row-f-r-suc"
            style={
              info?.status === 0
                ? { color: 'gray' }
                : info?.status === 1
                ? { color: 'green' }
                : info?.status === 2
                ? { color: 'red' }
                : info?.status === 4
                ? { color: 'red' }
                : {}
            }
          >
            {info?.status === 0 && 'Pending'}
            {info?.status === 1 && 'Accepted - Done'}
            {info?.status === 2 && 'Rejected'}
            {info?.status === 3 && 'Approve - Waiting'}

            {info?.status === 5 && 'Validation error - Rejected'}
            {info?.status === 6 && 'In queue - Accepted'}
            {info?.status === 7 && 'In queue - Rejected'}
          </div>

          <button
            className={'wall-row-f-r-more pointer'}
            onClick={() => setIsOpen(!isOpen)}
          >
            <img
              className={!isOpen ? 'rotate' : ''}
              src="/images/closeVector.svg"
              alt="closeVector"
              style={{
                opacity: type === 'internal' ? 0 : 1
              }}
            />
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="wall-row-second-det">
          <div className="wall-row-second">
            <div className="wall-row-second-pdf">
              {info?.is_effect !== 2 && methodTitle[type] === 'Transfer' ? (
                <Flex gap={1} direction={'col'}>
                  <div className="wall-row-second-combo">
                    <div className="wall-row-sec-title">Origin Island</div>
                    <div className="wall-row-second-val">{info?.title}</div>
                  </div>
                  <div className="wall-row-second-combo">
                    <div className="wall-row-sec-title">Destination Island</div>
                    <div className="wall-row-second-val">
                      {info?.destination_island}
                    </div>
                  </div>
                </Flex>
              ) : methodTitle[type] === 'Topup' ||
                methodTitle[type] === 'Participate' ? (
                <Flex gap={1} direction={'col'}>
                  <div className="wall-row-second-combo">
                    <div className="wall-row-sec-title">Origin Island</div>
                    <div className="wall-row-second-val">
                      {info?.destination_island}
                    </div>
                  </div>
                  <div className="wall-row-second-combo">
                    <div className="wall-row-sec-title">Destination Island</div>
                    <div className="wall-row-second-val">{info?.title}</div>
                  </div>
                </Flex>
              ) : null}
              {info?.status === 2 && (
                <div>
                  <div className="wall-row-second-des">Reason</div>
                  <div
                    className="wall-row-second-det"
                    dangerouslySetInnerHTML={{
                      __html: info?.description
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const InternalPaymentItem2 = ({
  info,
  detail,
  type
}: {
  info: any;
  detail?: any;
  type: number;
}): JSX.Element => {
  const { setUpdateTimer } = useContext(PrivateContext);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setUpdateTimer(+new Date());
  }, [isOpen]);

  const { transactions, loadTransaction } = useContext(WalletContext);

  const typeTitle = [
    'Gift',
    'Deduction',
    'Payment Plan',
    'Gift To Island',
    'Loan'
  ];
  const methodTitle = [
    'BOS Balance',
    'Gratitude',
    'BOS Balance & Island',
    'BOS Balance'
  ];

  return (
    <div className={isOpen ? 'wall-row' : 'wall-row closed'}>
      <div className="wall-row-f">
        <div className="wall-row-f-grid">
          <div className="wall-row-f-income">
            {info?.type === 1 && (
              <img src="/images/outcome.svg" alt="outcome.svg" />
            )}
            {info?.type === 0 && (
              <img src="/images/income.svg" alt="income.svg" />
            )}
          </div>
          <div className="wall-row-f-row">
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title102']}
              </div>
              <div className="wall-row-f-row-title-val">
                {info?.amount || Number(info?.amount) === 0
                  ? `$${numberWithCommas(info?.amount, 2)}`
                  : 'Loading...'}
              </div>
            </div>
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title103']}
              </div>
              <div className="wall-row-f-row-title-val">
                {typeTitle[info?.type]}
              </div>
            </div>
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title104']}
              </div>
              <div className="wall-row-f-row-title-val small">
                {methodTitle[type]}
              </div>
            </div>
            <div className="item-col">
              <div className="wall-row-f-row-title">
                {dictionary['MY-WALLET-title112']}
              </div>
              <div className="wall-row-f-row-title-val small">
                {/* {info?.created_at ? dateFormat(info?.created_at) : <div className='skeleton-element-item skeleton-box'></div>} */}
                {info?.created_at
                  ? `${timestampToLocalDaily(
                      Number(new Date(info?.created_at)) / 1000
                    )}, ${timestampToLocalHour(
                      Number(new Date(info?.created_at)) / 1000
                    )}`
                  : '◾️◾️◾️'}
              </div>
            </div>

            {info?.hash_id ? (
              <div className="item-col">
                <div className="wall-row-f-row-title">Tx ID</div>
                <div className="wall-row-f-row-title-val small">
                  {info?.hash_id
                    ? `#${String(info?.hash_id).toUpperCase()}`
                    : '◾️◾️◾️'}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="wall-row-f-r">
          <div className="wall-row-f-r-suc" style={{ color: 'gray' }}>
            {dictionary['MY-WALLET-title117']}
          </div>
          <button
            className="wall-row-f-r-more pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            <img
              className={!isOpen ? 'rotate' : ''}
              src="/images/closeVector.svg"
              alt="closeVector"
            />
          </button>
        </div>
      </div>

      {isOpen && <CryptoDetails info={info} detail={detail} isSpecial={true} />}
    </div>
  );
};

const MyWallet = (): JSX.Element => {
  const { flowState, settings, setUpdateTimer } = useContext(PrivateContext);
  const { userInfo, loadUserInfo, acceptHandle } = useContext(AccountContext);
  const navigate = useNavigate();

  const {
    setModalDeposit,
    modal,
    setModalWithdraw,
    modalTopupTransfer,
    setModalTopupTransfer,
    setModal,
    paymentList,
    loadPayments,
    depositList,
    loadDeposits,
    withdrawList,
    loadWithdraws,
    feesList,
    loadFees,
    GPList,
    loadGP,
    balances,
    balancesNew,
    loadBalances,
    transactions,
    loadTransactions,
    allPaymentList,
    loadAllPayments,
    withdrawFormData,
    loadAllPaymentsSorted,
    setWithdrawFormData,

    unlockStakeHandle,
    setUnlockStakeFormData,
    unlockStakeFormData,
    compoundAvailableStakeHandle,
    setCompoundAvailableStakeFormData,
    compoundAvailableStakeFormData,
    claimAvailableStakeHandle,
    setClaimAvailableStakeFormData,
    claimAvailableStakeFormData,
    renewStakeHandle,
    setRenewStakeFormData,
    renewStakeFormData,
    newStakeHandle,
    setNewStakeFormData,
    newStakeFormData,

    isModalOpenRenew,
    setIsModalOpenRenew,
    isModalOpenCompound,
    setIsModalOpenCompound,
    isModalOpenUnlock,
    setIsModalOpenUnlock,
    isModalOpenClaim,
    setIsModalOpenClaim,
    isModalOpenStake,
    setIsModalOpenStake,

    diamondGlobalData,
    loadDiamondBalance,
    loadDiamondActionHistory,
    setDiamondActionHistory,
    diamondActionHistory,
    diamondHistory,
    loadDiamondHistory
  } = useContext(WalletContext);
  const [current, setCurrent] = useState(1);

  const totalPages = 120;
  const [currentPage1, setCurrentPage1] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [currentPage3, setCurrentPage3] = useState(0);
  const [currentPage4, setCurrentPage4] = useState(0);
  const [currentPage5, setCurrentPage5] = useState(0);
  const [currentPage6, setCurrentPage6] = useState(0);

  const [isAsc, setIsAsc] = useState(true);

  useEffect(() => {
    loadUserInfo();
    loadBalances();
    loadWithdraws();
    loadFees();
    loadGP();
    loadTransactions();
    loadDeposits();
    loadDiamondActionHistory();

    const intervalA = setInterval(() => {
      loadBalances();
    }, 40000);
    const intervalB = setInterval(() => {
      loadTransactions();
      loadDeposits();
      loadDiamondActionHistory();
    }, 40000);

    return () => {
      clearInterval(intervalA);
      clearInterval(intervalB);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setUpdateTimer(+new Date());
    // current === 0 && currentPage1 > 0 && loadAllPayments(currentPage1);
    current === 1 && currentPage2 > 0 && loadDeposits(currentPage2);
    current === 2 && currentPage3 > 0 && loadWithdraws(currentPage3);
    current === 3 && currentPage4 > 0 && loadFees(currentPage4);
    current === 4 && currentPage5 > 0 && loadGP(currentPage5);
    current === 5 && currentPage6 > 0 && loadDiamondActionHistory(currentPage6);
  }, [
    currentPage1,
    currentPage2,
    currentPage3,
    currentPage4,
    currentPage5,
    currentPage6,
    current
  ]);

  const sortClickHandler = (sortType: string) => {
    setIsAsc(sortType === 'asc' ? true : false);

    current === 1 && loadAllPaymentsSorted(currentPage2, sortType);

    current === 2 &&
      currentPage3 > 0 &&
      loadAllPaymentsSorted(currentPage3, sortType);

    current === 4 &&
      currentPage4 > 0 &&
      loadAllPaymentsSorted(currentPage4, sortType);

    current === 5 &&
      currentPage4 > 0 &&
      loadAllPaymentsSorted(currentPage5, sortType);

    // loadAllPaymentsSorted(cu);
  };
  const [transferDisabledModal, setTransferDisabledModal] = useState(false);
  const [agreementJustForRead, setAgreementJustForRead] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [isReadAgreement, setIsReadAgreement] = useState(true);

  const [isOpenAgreementModal4, setisOpenAgreementModal4] = useState(false);
  const [isOpenAgreementModal5, setisOpenAgreementModal5] = useState(false);

  const agreementRef = useRef<any>(null);
  useEffect(() => {
    // const handleScroll = () => {
    //   if (agreementRef.current) {
    //     const visibleHeight = agreementRef.current.clientHeight;
    //     const scrollableHeight = agreementRef.current.scrollHeight;
    //     const position = agreementRef.current.scrollTop;
    //     if (Number(position + visibleHeight).toFixed() == scrollableHeight) {
    //       setIsReadAgreement(true);
    //     }
    //   }
    // };
    // if (isReadAgreement === false) {
    //   // Add event listener to the div element
    //   if (agreementRef.current) {
    //     agreementRef.current.addEventListener('scroll', handleScroll);
    //   }
    // }
    // // Remove the event listener when the component unmounts
    // return () => {
    //   if (agreementRef.current) {
    //     agreementRef.current.removeEventListener('scroll', handleScroll);
    //   }
    // };
  });

  if (flowState?.step === 17 && userInfo && userInfo?.membershipStatus !== 2)
    return (
      <Layout className="wall">
        <>
          <Modal
            title="Transfer from Main Balance"
            image={modalImage}
            min={diamondGlobalData?.minimum_stake}
            max={Number(
              Number(balancesNew?.main_balance) +
                Number(balancesNew?.bos_interest)
            ).toFixed(2)}
            isOpen={isModalOpenStake}
            setIsOpen={setIsModalOpenStake}
            balance={
              <div
                className="w-full text-center flex justify-center gap-6 cursor-pointer"
                onClick={() =>
                  setNewStakeFormData({
                    ...newStakeFormData,
                    amount: (
                      Number(balancesNew?.main_balance) +
                      Number(balancesNew?.bos_interest)
                    ).toFixed(2)
                  })
                }
              >
                Main Balance:{' '}
                <b>
                  {balancesNew?.main_balance ||
                  balancesNew?.bos_interest ||
                  balancesNew?.main_balance === 0 ||
                  balancesNew?.bos_interest === 0 ? (
                    `$${numberWithCommas(
                      Number(balancesNew?.main_balance) +
                        Number(balancesNew?.bos_interest),
                      2
                    )}`
                  ) : (
                    <div className="skeleton-element-item skeleton-box"></div>
                  )}
                </b>
              </div>
            }
            onChange={(event) =>
              setNewStakeFormData({
                ...newStakeFormData,
                amount: event.target.value
              })
            }
            onClick={() => newStakeHandle()}
            value={newStakeFormData}
            setValue={setNewStakeFormData}
          />

          <ModalAgreement
            ckEditorKey={17}
            isOpen={isOpenAgreementModal4}
            onClick={() => {
              setIsModalOpenStake(true);
              setisOpenAgreementModal4(false);
            }}
            onClose={() => setisOpenAgreementModal4(false)}
          />
          <ModalAgreement
            ckEditorKey={18}
            isOpen={isOpenAgreementModal5}
            onClick={() => {
              setModalDeposit(true);
              setisOpenAgreementModal5(false);
            }}
            onClose={() => setisOpenAgreementModal5(false)}
          />

          <Container x={'center'} marginT={1} className="container">
            <Flex direction={'col'} gap={1.7} x={'center'} y={'start'}>
              <div className="wall-h" style={{ width: '100%' }}>
                <Header title="My Wallet" />
              </div>

              {userInfo?.my_wallet_agreement_status === 1 &&
                agreementJustForRead === false && (
                  <>
                    {transferDisabledModal && (
                      <div
                        className="blur-bg"
                        onClick={() => setTransferDisabledModal(false)}
                      ></div>
                    )}
                    {transferDisabledModal && (
                      <div className="mod">
                        <div className="mod-border">
                          <div className="mod-box">
                            <p className="mod-box-p">
                              {dictionary['MY-WALLET-title19']}
                            </p>
                            <button
                              className="mod-box-btn"
                              onClick={() => setTransferDisabledModal(false)}
                            >
                              X
                            </button>
                          </div>
                          <div className="mod-inside">
                            <div
                              style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                gap: '0.5rem',
                                maxWidth: '300px'
                              }}
                            >
                              <p>{dictionary['MY-WALLET-title124']}</p>
                            </div>
                            <hr />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="wall-body">
                      <div className="wall-body-inside">
                        <div className="wall-body-post">
                          {balances ? (
                            <>
                              <div className="wall-body-post-h">
                                <Flex y="center" gap={0.5}>
                                  <div className="wall-body-post-h-t">
                                    {dictionary['MY-WALLET-title1']}
                                  </div>
                                  <div
                                    className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                    style={{
                                      left: 0,
                                      width: 20,
                                      margin: '-14px 0px 0px 4px'
                                    }}
                                  >
                                    <AiOutlineInfoCircle className="tooltip-info-icon" />
                                    <div className="tooltip-info-text">
                                      <span>
                                        {dictionary['MY-WALLET-title2']}
                                      </span>
                                    </div>
                                  </div>
                                </Flex>

                                <div className="wall-body-post-h-val">
                                  {balancesNew?.main_balance ||
                                  balancesNew?.bos_interest ||
                                  balancesNew?.main_balance === 0 ||
                                  balancesNew?.bos_interest === 0 ? (
                                    `$${numberWithCommas(
                                      Number(balancesNew?.main_balance) +
                                        Number(balancesNew?.bos_interest),
                                      2
                                    )}`
                                  ) : (
                                    <div className="skeleton-element-item skeleton-box"></div>
                                  )}
                                </div>
                              </div>

                              <div className="wall-body-post-mid">
                                <div className="wall-body-post-mid-bottom">
                                  <div className="wall-body-post-mid-bottom-div">
                                    <div className="wall-body-post-mid-bottom-title">
                                      {dictionary['MY-WALLET-title3']}
                                    </div>

                                    <div className="wall-body-post-mid-bottom-title-val">
                                      {balancesNew?.main_balance ||
                                      balancesNew?.main_balance === 0 ? (
                                        `$${numberWithCommas(
                                          balancesNew?.main_balance,
                                          2
                                        )}`
                                      ) : (
                                        <div className="skeleton-element-item skeleton-box"></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="wall-body-post-mid-bottom">
                                  <div className="wall-body-post-mid-bottom-div">
                                    <div className="wall-body-post-mid-bottom-title">
                                      {dictionary['MY-WALLET-title4']}
                                    </div>
                                    <div className="wall-body-post-mid-bottom-title-val">
                                      {balancesNew?.bos_interest ||
                                      balancesNew?.bos_interest === 0 ? (
                                        `$${numberWithCommas(
                                          balancesNew?.bos_interest,
                                          2
                                        )}`
                                      ) : (
                                        <div className="skeleton-element-item skeleton-box"></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="wall-body-post-mid">
                                <div className="wall-body-post-mid-bottom">
                                  <div className="wall-body-post-mid-bottom-div">
                                    <Flex y="center" gap={0.5}>
                                      <div className="wall-body-post-mid-bottom-title">
                                        {dictionary['MY-WALLET-title5']}
                                      </div>
                                      <div
                                        className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                        style={{
                                          left: 0,
                                          width: 20,
                                          margin: '-22px 0px 0px 4px'
                                        }}
                                      >
                                        <AiOutlineInfoCircle className="tooltip-info-icon" />
                                        <div className="tooltip-info-text">
                                          <span>
                                            {dictionary['MY-WALLET-title6']}
                                          </span>
                                        </div>
                                      </div>
                                    </Flex>

                                    <div className="wall-body-post-mid-bottom-title-val">
                                      {balances ? (
                                        balances?.payment_deposit ||
                                        balances?.payment_deposit === 0 ? (
                                          `$${numberWithCommas(
                                            balances?.payment_deposit,
                                            2
                                          )}`
                                        ) : (
                                          <div className="skeleton-element-item skeleton-box"></div>
                                        )
                                      ) : (
                                        <div className="skeleton-element-item skeleton-box"></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="wall-body-post-mid-bottom">
                                  <div className="wall-body-post-mid-bottom-div">
                                    <Flex y="center" gap={0.5}>
                                      <div className="wall-body-post-mid-bottom-title">
                                        {dictionary['MY-WALLET-title7']}
                                      </div>
                                      <div
                                        className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                        style={{
                                          left: 0,
                                          width: 20,
                                          margin: '-22px 0px 0px 4px'
                                        }}
                                      >
                                        <AiOutlineInfoCircle className="tooltip-info-icon" />
                                        <div className="tooltip-info-text">
                                          <span>
                                            {dictionary['MY-WALLET-title8']}
                                          </span>
                                        </div>
                                      </div>
                                    </Flex>

                                    <div className="wall-body-post-mid-bottom-title-val">
                                      {balances ? (
                                        balances?.payment_withdraw ||
                                        balances?.payment_withdraw === 0 ? (
                                          `$${numberWithCommas(
                                            balances?.payment_withdraw,
                                            2
                                          )}`
                                        ) : (
                                          <div className="skeleton-element-item skeleton-box"></div>
                                        )
                                      ) : (
                                        <div className="skeleton-element-item skeleton-box"></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="wall-body-post-mid-combo">
                                <button
                                  className="wall-body-post-mid-btn"
                                  style={
                                    userInfo?.is_action_limit === 0
                                      ? {}
                                      : {
                                          opacity: 0.5,
                                          cursor: 'not-allowed'
                                        }
                                  }
                                  onClick={() => {
                                    if (userInfo?.is_action_limit === 0) {
                                      setisOpenAgreementModal5(true);
                                    }
                                    // setModalDeposit(true);
                                  }}
                                >
                                  {dictionary['MY-WALLET-title9']}
                                </button>
                                <button
                                  className="wall-body-post-mid-btn"
                                  onClick={() => {
                                    setWithdrawFormData({
                                      ...withdrawFormData,
                                      payment_balance: 0
                                    });
                                    setModalWithdraw(true);
                                  }}
                                >
                                  {dictionary['MY-WALLET-title10']}
                                </button>
                              </div>

                              <div className="grid grid-cols-2 justify-center gap-[16px] mt-4">
                                <button
                                  className="wall-body-post-mid-btn"
                                  style={
                                    userInfo?.is_action_limit === 0
                                      ? {}
                                      : {
                                          opacity: 0.5,
                                          cursor: 'not-allowed'
                                        }
                                  }
                                  onClick={() => {
                                    if (userInfo?.is_action_limit === 0) {
                                      setisOpenAgreementModal4(true);
                                    }
                                  }}
                                >
                                  {dictionary['MY-WALLET-title10-1']}
                                </button>
                                <span
                                  className="financial-tx-info-link w-full flex justify-center items-center"
                                  onClick={() => setAgreementJustForRead(true)}
                                >
                                  Financial Transactions Agreement
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="wall-body-post-h">
                                <div className="wall-body-post-h-t">
                                  {dictionary['MY-WALLET-title11']}
                                </div>
                                <div className="wall-body-post-h-val">
                                  <div className="skeleton-element-item skeleton-box"></div>
                                </div>
                              </div>

                              <div className="wall-body-post-mid">
                                <div className="wall-body-post-mid-bottom">
                                  <div className="wall-body-post-mid-bottom-div">
                                    <div className="wall-body-post-mid-bottom-title">
                                      {dictionary['MY-WALLET-title3']}
                                    </div>
                                    <div className="wall-body-post-mid-bottom-title-val">
                                      <div className="skeleton-element-item skeleton-box"></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wall-body-post-mid-bottom">
                                  <div className="wall-body-post-mid-bottom-div">
                                    <div className="wall-body-post-mid-bottom-title">
                                      {dictionary['MY-WALLET-title4']}
                                    </div>
                                    <div className="wall-body-post-mid-bottom-title-val">
                                      <div className="skeleton-element-item skeleton-box"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="wall-body-post-mid">
                                <div className="wall-body-post-mid-bottom">
                                  <div className="wall-body-post-mid-bottom-div">
                                    <div className="wall-body-post-mid-bottom-title">
                                      {dictionary['MY-WALLET-title5']}
                                    </div>
                                    <div className="wall-body-post-mid-bottom-title-val">
                                      <div className="skeleton-element-item skeleton-box"></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wall-body-post-mid-bottom">
                                  <div className="wall-body-post-mid-bottom-div">
                                    <div className="wall-body-post-mid-bottom-title">
                                      {dictionary['MY-WALLET-title7']}
                                    </div>
                                    <div className="wall-body-post-mid-bottom-title-val">
                                      <div className="skeleton-element-item skeleton-box"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="wall-body-post-mid-combo">
                                <button
                                  className="wall-body-post-mid-btn"
                                  disabled={true}
                                >
                                  <div className="skeleton-element-item skeleton-box"></div>
                                </button>
                                <button
                                  className="wall-body-post-mid-btn"
                                  disabled={true}
                                >
                                  <div className="skeleton-element-item skeleton-box"></div>
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="wall-body-post">
                          {balances ? (
                            <>
                              <div className="wall-body-post-h">
                                <Flex y="center" gap={0.5}>
                                  <div className="wall-body-post-h-t">
                                    {dictionary['MY-WALLET-title14']}
                                  </div>
                                  <div
                                    className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                    style={{
                                      left: 0,
                                      width: 20,
                                      margin: '-14px 0px 0px 4px'
                                    }}
                                  >
                                    <AiOutlineInfoCircle className="tooltip-info-icon" />
                                    <div className="tooltip-info-text">
                                      <span>
                                        {dictionary['MY-WALLET-title15']}
                                      </span>
                                    </div>
                                  </div>
                                </Flex>

                                <div className="wall-body-post-h-val">
                                  {balances?.gratitute_balance ||
                                  balances?.gratitute_balance === 0 ? (
                                    `$${numberWithCommas(
                                      balances?.gratitute_balance,
                                      2
                                    )}`
                                  ) : (
                                    <div className="skeleton-element-item skeleton-box"></div>
                                  )}
                                </div>
                              </div>

                              <div className="wall-body-post-mid">
                                <div
                                  className="wall-body-post-mid-bottom"
                                  style={{
                                    height: 165
                                  }}
                                >
                                  <div className="wall-body-post-mid-bottom-div">
                                    <Flex y="center" gap={0.5}>
                                      <div className="wall-body-post-mid-bottom-title">
                                        {dictionary['MY-WALLET-title16']}
                                      </div>
                                      <div
                                        className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                        style={{
                                          left: 0,
                                          width: 20,
                                          margin: '-22px 0px 0px 4px'
                                        }}
                                      >
                                        <AiOutlineInfoCircle className="tooltip-info-icon" />
                                        <div className="tooltip-info-text">
                                          <span>
                                            {dictionary['MY-WALLET-title17']}
                                          </span>
                                        </div>
                                      </div>
                                    </Flex>
                                    <div className="wall-body-post-mid-bottom-title-val">
                                      {balances ? (
                                        balances?.total_commission ||
                                        balances?.total_commission === 0 ? (
                                          `$${numberWithCommas(
                                            balances?.total_commission,
                                            2
                                          )}`
                                        ) : (
                                          <div className="skeleton-element-item skeleton-box"></div>
                                        )
                                      ) : (
                                        <div className="skeleton-element-item skeleton-box"></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="wall-body-post-mid-bottom">
                                  <div className="wall-body-post-mid-bottom-div">
                                    <Flex y="center" gap={0.5}>
                                      <div className="wall-body-post-mid-bottom-title">
                                        {dictionary['MY-WALLET-title7']}
                                      </div>
                                      <div
                                        className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                        style={{
                                          left: 0,
                                          width: 20,
                                          margin: '-22px 0px 0px 4px'
                                        }}
                                      >
                                        <AiOutlineInfoCircle className="tooltip-info-icon" />
                                        <div className="tooltip-info-text">
                                          <span>
                                            {dictionary['MY-WALLET-title18']}
                                          </span>
                                        </div>
                                      </div>
                                    </Flex>
                                    <div className="wall-body-post-mid-bottom-title-val">
                                      {balances?.total_gratitude_withdraw ||
                                      balances?.total_gratitude_withdraw ===
                                        0 ? (
                                        `$${numberWithCommas(
                                          balances?.total_gratitude_withdraw,
                                          2
                                        )}`
                                      ) : (
                                        <div className="skeleton-element-item skeleton-box"></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="wall-body-post-h">
                                <div className="wall-body-post-h-t">
                                  {dictionary['MY-WALLET-title14']}
                                </div>
                                <div className="wall-body-post-h-val">
                                  <div className="skeleton-element-item skeleton-box"></div>
                                </div>
                              </div>

                              <div className="wall-body-post-mid">
                                <div
                                  className="wall-body-post-mid-bottom"
                                  style={{
                                    height: 165
                                  }}
                                >
                                  <div className="wall-body-post-mid-bottom-div">
                                    <div className="wall-body-post-mid-bottom-title">
                                      {dictionary['MY-WALLET-title16']}
                                    </div>
                                    <div className="wall-body-post-mid-bottom-title-val">
                                      <div className="skeleton-element-item skeleton-box"></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wall-body-post-mid-bottom">
                                  <div className="wall-body-post-mid-bottom-div">
                                    <div className="wall-body-post-mid-bottom-title">
                                      {dictionary['MY-WALLET-title7']}
                                    </div>
                                    <div className="wall-body-post-mid-bottom-title-val">
                                      <div className="skeleton-element-item skeleton-box"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="wall-body-post-mid-r-r">
                                <button
                                  className="wall-body-post-mid-r-btn"
                                  disabled={true}
                                >
                                  <div className="skeleton-element-item skeleton-box"></div>
                                </button>
                                <button
                                  className="wall-body-post-mid-r-btn"
                                  disabled={true}
                                >
                                  <div className="skeleton-element-item skeleton-box"></div>
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="wall-post">
                        <div className="wall-post-h">
                          <div className="wall-post-h-l">
                            {/* <button
                        onClick={() => setCurrent(0)}
                        className={
                          current === 0
                            ? 'wall-post-h-l-all'
                            : 'wall-post-h-l-dep'
                        }
                      >
                        All
                      </button> */}

                            <Flex
                              width="fit-content"
                              y="center"
                              x="end"
                              gap={0.5}
                            >
                              <div
                                className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                style={{ right: 0, top: 0 }}
                              >
                                <div className="tooltip-info-icon">
                                  <button
                                    onClick={() => setCurrent(1)}
                                    className={
                                      current === 1
                                        ? 'wall-post-h-l-all'
                                        : 'wall-post-h-l-dep'
                                    }
                                  >
                                    {dictionary['MY-WALLET-title9']}
                                  </button>
                                </div>
                                <div
                                  className="tooltip-info-text"
                                  style={{ top: '30px' }}
                                >
                                  <span>{dictionary['MY-WALLET-title21']}</span>
                                </div>
                              </div>
                            </Flex>

                            <Flex
                              width="fit-content"
                              y="center"
                              x="end"
                              gap={0.5}
                            >
                              <div
                                className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                style={{ right: 0, top: 0 }}
                              >
                                <div className="tooltip-info-icon">
                                  <button
                                    onClick={() => setCurrent(2)}
                                    className={
                                      current === 2
                                        ? 'wall-post-h-l-all'
                                        : 'wall-post-h-l-dep'
                                    }
                                  >
                                    {dictionary['MY-WALLET-title22']}
                                  </button>
                                </div>
                                <div
                                  className="tooltip-info-text"
                                  style={{ top: '30px' }}
                                >
                                  <span>{dictionary['MY-WALLET-title23']}</span>
                                </div>
                              </div>
                            </Flex>

                            <Flex
                              width="fit-content"
                              y="center"
                              x="end"
                              gap={0.5}
                            >
                              <div
                                className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                style={{ right: 0, top: 0 }}
                              >
                                <div className="tooltip-info-icon">
                                  <button
                                    onClick={() => setCurrent(3)}
                                    className={
                                      current === 3
                                        ? 'wall-post-h-l-all'
                                        : 'wall-post-h-l-dep'
                                    }
                                  >
                                    {dictionary['MY-WALLET-title24']}
                                  </button>
                                </div>
                                <div
                                  className="tooltip-info-text"
                                  style={{ top: '30px' }}
                                >
                                  <span>{dictionary['MY-WALLET-title25']}</span>
                                </div>
                              </div>
                            </Flex>

                            <Flex
                              width="fit-content"
                              y="center"
                              x="end"
                              gap={0.5}
                            >
                              <div
                                className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                style={{ right: 0, top: 0 }}
                              >
                                <div className="tooltip-info-icon">
                                  <button
                                    onClick={() => setCurrent(4)}
                                    className={
                                      current === 4
                                        ? 'wall-post-h-l-all'
                                        : 'wall-post-h-l-dep'
                                    }
                                  >
                                    {dictionary['MY-WALLET-title26']}
                                  </button>
                                </div>
                                <div
                                  className="tooltip-info-text"
                                  style={{ top: '30px' }}
                                >
                                  <span>{dictionary['MY-WALLET-title27']}</span>
                                </div>
                              </div>
                            </Flex>
                            <Flex
                              width="fit-content"
                              y="center"
                              x="end"
                              gap={0.5}
                            >
                              <div
                                className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
                                style={{ right: 0, top: 0 }}
                              >
                                <div className="tooltip-info-icon">
                                  <button
                                    onClick={() => setCurrent(5)}
                                    className={
                                      current === 5
                                        ? 'wall-post-h-l-all'
                                        : 'wall-post-h-l-dep'
                                    }
                                  >
                                    {dictionary['MY-WALLET-title140']}
                                  </button>
                                </div>
                                <div
                                  className="tooltip-info-text"
                                  style={{ top: '30px' }}
                                >
                                  <span>
                                    {dictionary['MY-WALLET-title140-1']}
                                  </span>
                                </div>
                              </div>
                            </Flex>

                            {/* <div className="wall-post-h-l-sort">
                        <div className="wall-post-h-l-sort-title-wrapper">
                          <span>Sort by date</span>
                          <img src={customArrowIcon} alt="arrow" />
                        </div>
                        <div className="wall-post-h-l-sort-values">
                          <span
                            onClick={() => sortClickHandler('asc')}
                            className={`${isAsc && 'active'}`}
                          >
                            ASC
                          </span>
                          <span
                            onClick={() => sortClickHandler('desc')}
                            className={`${!isAsc && 'active'}`}
                          >
                            DESC
                          </span>
                        </div>
                      </div> */}
                          </div>

                          {/* <div className="wall-post-h-mid">
                      {transactions ? (
                        <div className="wall-post-h-mid-parent">
                          <div
                            className="wall-post-h-mid-pen"
                            style={{ color: 'green' }}
                          >
                            Accepted({transactions?.total_accepted})
                          </div>
                          <div
                            className="wall-post-h-mid-succ"
                            style={{ color: 'gray' }}
                          >
                            Pending({transactions?.total_pending})
                          </div>
                          <div
                            className="wall-post-h-mid-fail"
                            style={{ color: 'red' }}
                          >
                            Rejected({transactions?.total_rejected})
                          </div>
                        </div>
                      ) : (
                        <div className='skeleton-element-item skeleton-box'></div>
                      )}
                    </div> */}
                        </div>

                        {current === 1 ? (
                          depositList ? (
                            <>
                              {depositList?.msg?.length > 0 ? (
                                depositList?.msg?.map(
                                  (payment: any, index: number) => (
                                    <PaymentItem
                                      key={index}
                                      info={payment}
                                      type={
                                        payment?.payment_type == 5
                                          ? 'internal'
                                          : payment?.payment_type == 1 ||
                                            payment?.payment_type == 8 ||
                                            payment?.payment_type == 9 ||
                                            payment?.payment_type == 3
                                          ? 'Crypto'
                                          : 'Wire'
                                      }
                                    />
                                  )
                                )
                              ) : (
                                <Flex y="center" x="center">
                                  <img src={NotFoundImage} />
                                </Flex>
                              )}
                            </>
                          ) : (
                            <>
                              {['', '', '', '', '', '', '']?.map(
                                (payment: any, index: number) => (
                                  <div className="wall-row closed">
                                    <div className="wall-row-f">
                                      <div className="wall-row-f-grid">
                                        <div className="wall-row-f-income"></div>
                                        <div className="wall-row-f-row">
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title28']}
                                            </div>
                                            <div className="wall-row-f-row-title-val">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title29']}
                                            </div>
                                            <div className="wall-row-f-row-title-val">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title30']}
                                            </div>
                                            <div className="wall-row-f-row-title-val small">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title31']}
                                            </div>
                                            <div className="wall-row-f-row-title-val small">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="wall-row-f-r">
                                        <div
                                          className="wall-row-f-r-suc"
                                          style={{ color: 'gray' }}
                                        >
                                          <div className="skeleton-element-item skeleton-box"></div>
                                        </div>
                                        <button className="wall-row-f-r-more pointer"></button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          )
                        ) : null}
                        {current === 2 ? (
                          withdrawList ? (
                            <>
                              {withdrawList?.msg?.length > 0 ? (
                                withdrawList?.msg?.map(
                                  (payment: any, index: number) => (
                                    <PaymentItemNew
                                      key={index}
                                      info={payment}
                                      type={
                                        payment?.payment_type === 1 ||
                                        payment?.payment_type === 3
                                          ? 'Crypto'
                                          : 'Wire'
                                      }
                                    />
                                  )
                                )
                              ) : (
                                <Flex y="center" x="center">
                                  <img src={NotFoundImage} />
                                </Flex>
                              )}
                            </>
                          ) : (
                            <>
                              {['', '', '', '', '', '', '']?.map(
                                (payment: any, index: number) => (
                                  <div className="wall-row closed">
                                    <div className="wall-row-f">
                                      <div className="wall-row-f-grid">
                                        <div className="wall-row-f-income"></div>
                                        <div className="wall-row-f-row">
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title28']}
                                            </div>
                                            <div className="wall-row-f-row-title-val">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title29']}
                                            </div>
                                            <div className="wall-row-f-row-title-val">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title30']}
                                            </div>
                                            <div className="wall-row-f-row-title-val small">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title31']}
                                            </div>
                                            <div className="wall-row-f-row-title-val small">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="wall-row-f-r">
                                        <div
                                          className="wall-row-f-r-suc"
                                          style={{ color: 'gray' }}
                                        >
                                          <div className="skeleton-element-item skeleton-box"></div>
                                        </div>
                                        <button className="wall-row-f-r-more pointer"></button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          )
                        ) : null}
                        {current === 3 ? (
                          feesList ? (
                            <>
                              {feesList?.msg?.length > 0 ? (
                                feesList?.msg?.map(
                                  (payment: any, index: number) => (
                                    <InternalPaymentItem
                                      key={index}
                                      info={payment}
                                      type={payment.type}
                                    />
                                  )
                                )
                              ) : (
                                <Flex y="center" x="center">
                                  <img src={NotFoundImage} />
                                </Flex>
                              )}
                            </>
                          ) : (
                            <>
                              {['', '', '', '', '', '', '']?.map(
                                (payment: any, index: number) => (
                                  <div className="wall-row closed">
                                    <div className="wall-row-f">
                                      <div className="wall-row-f-grid">
                                        <div className="wall-row-f-income"></div>
                                        <div className="wall-row-f-row">
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title28']}
                                            </div>
                                            <div className="wall-row-f-row-title-val">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title29']}
                                            </div>
                                            <div className="wall-row-f-row-title-val">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title30']}
                                            </div>
                                            <div className="wall-row-f-row-title-val small">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title31']}
                                            </div>
                                            <div className="wall-row-f-row-title-val small">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="wall-row-f-r">
                                        <div
                                          className="wall-row-f-r-suc"
                                          style={{ color: 'gray' }}
                                        >
                                          <div className="skeleton-element-item skeleton-box"></div>
                                        </div>
                                        <button className="wall-row-f-r-more pointer"></button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          )
                        ) : null}
                        {current === 4 ? (
                          GPList ? (
                            <>
                              {GPList?.msg?.length > 0 ? (
                                GPList?.msg?.map(
                                  (payment: any, index: number) => (
                                    <InternalPaymentItem2
                                      key={index}
                                      info={payment}
                                      type={payment.balance_type}
                                    />
                                  )
                                )
                              ) : (
                                <Flex y="center" x="center">
                                  <img src={NotFoundImage} />
                                </Flex>
                              )}
                            </>
                          ) : (
                            <>
                              {['', '', '', '', '', '', '']?.map(
                                (payment: any, index: number) => (
                                  <div className="wall-row closed">
                                    <div className="wall-row-f">
                                      <div className="wall-row-f-grid">
                                        <div className="wall-row-f-income"></div>
                                        <div className="wall-row-f-row">
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title28']}
                                            </div>
                                            <div className="wall-row-f-row-title-val">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title29']}
                                            </div>
                                            <div className="wall-row-f-row-title-val">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title30']}
                                            </div>
                                            <div className="wall-row-f-row-title-val small">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title31']}
                                            </div>
                                            <div className="wall-row-f-row-title-val small">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="wall-row-f-r">
                                        <div
                                          className="wall-row-f-r-suc"
                                          style={{ color: 'gray' }}
                                        >
                                          <div className="skeleton-element-item skeleton-box"></div>
                                        </div>
                                        <button className="wall-row-f-r-more pointer"></button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          )
                        ) : null}
                        {current === 5 ? (
                          diamondActionHistory ? (
                            <>
                              {diamondActionHistory?.msg?.length > 0 ? (
                                diamondActionHistory?.msg?.map(
                                  (payment: any, index: number) => (
                                    <PaymentItemNew33
                                      key={index}
                                      info={payment}
                                      type={
                                        payment?.payment_type === 1 ||
                                        payment?.payment_type === 3
                                          ? 'Crypto'
                                          : 'Wire'
                                      }
                                    />
                                  )
                                )
                              ) : (
                                <Flex y="center" x="center">
                                  <img src={NotFoundImage} />
                                </Flex>
                              )}
                            </>
                          ) : (
                            <>
                              {['', '', '', '', '', '', '']?.map(
                                (payment: any, index: number) => (
                                  <div className="wall-row closed">
                                    <div className="wall-row-f">
                                      <div className="wall-row-f-grid">
                                        <div className="wall-row-f-income"></div>
                                        <div className="wall-row-f-row">
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title28']}
                                            </div>
                                            <div className="wall-row-f-row-title-val">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title29']}
                                            </div>
                                            <div className="wall-row-f-row-title-val">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title30']}
                                            </div>
                                            <div className="wall-row-f-row-title-val small">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                          <div className="item-col">
                                            <div className="wall-row-f-row-title">
                                              {dictionary['MY-WALLET-title31']}
                                            </div>
                                            <div className="wall-row-f-row-title-val small">
                                              <div className="skeleton-element-item skeleton-box"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="wall-row-f-r">
                                        <div
                                          className="wall-row-f-r-suc"
                                          style={{ color: 'gray' }}
                                        >
                                          <div className="skeleton-element-item skeleton-box"></div>
                                        </div>
                                        <button className="wall-row-f-r-more pointer"></button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          )
                        ) : null}

                        {current === 1 && (
                          <Pagination
                            className="pagination"
                            total={depositList?.total_page}
                            current={depositList?.current_page}
                            maxWidth={300}
                            a11yActiveLabel=""
                            previousLabel=" "
                            nextLabel=" "
                            renderNav={false}
                            ariaPreviousLabel="Prev"
                            ariaNextLabel="Next"
                            onPageChange={(page: any) => setCurrentPage2(page)}
                          />
                        )}
                        {current === 2 && (
                          <Pagination
                            className="pagination"
                            total={withdrawList?.total_page}
                            current={withdrawList?.current_page}
                            maxWidth={300}
                            a11yActiveLabel=""
                            previousLabel=" "
                            nextLabel=" "
                            renderNav={false}
                            ariaPreviousLabel="Prev"
                            ariaNextLabel="Next"
                            onPageChange={(page: any) => setCurrentPage3(page)}
                          />
                        )}
                        {current === 3 && (
                          <Pagination
                            className="pagination"
                            total={feesList?.totalPage}
                            current={feesList?.current_page}
                            maxWidth={300}
                            a11yActiveLabel=""
                            previousLabel=" "
                            nextLabel=" "
                            renderNav={false}
                            ariaPreviousLabel="Prev"
                            ariaNextLabel="Next"
                            onPageChange={(page: any) => setCurrentPage4(page)}
                          />
                        )}
                        {current === 4 && (
                          <Pagination
                            className="pagination"
                            total={GPList?.totalPage}
                            current={GPList?.current_page}
                            maxWidth={300}
                            a11yActiveLabel=""
                            previousLabel=" "
                            nextLabel=" "
                            renderNav={false}
                            ariaPreviousLabel="Prev"
                            ariaNextLabel="Next"
                            onPageChange={(page: any) => setCurrentPage5(page)}
                          />
                        )}
                        {current === 5 && (
                          <Pagination
                            className="pagination"
                            total={diamondActionHistory?.totalPage}
                            current={diamondActionHistory?.current_page}
                            maxWidth={300}
                            a11yActiveLabel=""
                            previousLabel=" "
                            nextLabel=" "
                            renderNav={false}
                            ariaPreviousLabel="Prev"
                            ariaNextLabel="Next"
                            onPageChange={(page: any) => setCurrentPage6(page)}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}

              {(userInfo?.my_wallet_agreement_status === 0 ||
                agreementJustForRead === true) && (
                <>
                  <div className="boscrew-t-row">
                    <div className="boscrew-t-h">
                      {dictionary['MY-WALLET-title134']}
                    </div>

                    <div className="boscrew-t-cap" ref={agreementRef}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: settings?.mywallet_agreement
                        }}
                      ></div>
                      <div className="boscrew-t-combo">
                        <input
                          type="checkbox"
                          id="horns"
                          name="horns"
                          onChange={(e) => setIsAccept(e.target.checked)}
                        />
                        <label className="boscrew-t-label" htmlFor="horns">
                          {dictionary['MY-WALLET-title135']}
                        </label>
                      </div>
                    </div>
                    <div className="boscrew-t-end">
                      {agreementJustForRead === true ? (
                        <>
                          <button
                            className={'intr-last-row-right-btn'}
                            onClick={() => setAgreementJustForRead(false)}
                          >
                            {dictionary['MY-WALLET-title137']}
                          </button>
                        </>
                      ) : (
                        <div className="boscrew-t-line">
                          <div className="boscrew-t-for-btn">
                            <button
                              className={
                                isAccept
                                  ? 'intr-last-row-right-btn'
                                  : 'intr-last-row-right-btn disable'
                              }
                              disabled={!isAccept}
                              onClick={() => (isAccept ? acceptHandle() : null)}
                            >
                              {dictionary['MY-WALLET-title136']}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Flex>
          </Container>
          {/* <Modal /> */}
          {/* <ModalDeposit /> */}

          <TransferModal />
          <WithdrawModal />
          <TransferToIslandModal />
          <MyWalletDepositModal />
          {/* <SuccessModal requestedAmount={1000} txID='00082679913' method={ModalMethods.ForceWithdraw} time='25 Feb 2023, 13:22' fee={500} finalAmount={500} /> */}
        </>
      </Layout>
    );
  else return <>{navigate('/')}</>;
};

export default MyWallet;
