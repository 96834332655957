import toast from 'react-hot-toast';
import { AccountType, AccountTypeDescription, ApplicationUser, CountriesList, QuestionInWatchVideo, UserStatus, UserStep, VerifyCode, VerifyWatchVideo } from '../interfaces/wizardModel';
import { ApplicationForm, WatchVideoForm } from '../types/wizardTypes';
import { toPascalCase } from '../utils/tools';
import HttpService, { catchReporter, checkMaintenance, reporter } from "./httpService";

class WizardService {
    private _httpServiceAuth = HttpService('Authentication');
    private _httpServiceSophisticate = HttpService('Sophisticate');
    private _httpServiceCountry = HttpService('Country');

    
    async submitSophisticateFrom(formData: any) {
        try {
            const response = await this._httpServiceSophisticate.post<any>('/Submit', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                if (typeof response.data.error === 'string') {
                    reporter(response)
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    reporter(response)
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getStatus() {
        try {
            const response = await this._httpServiceAuth.get<UserStatus>('/UserStatus');
            checkMaintenance(response)

            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getStep() {
        try {
            const response = await this._httpServiceAuth.get<any>('/UserStep');
            const finalObject = {
                step: response?.data?.message?.step,
                menu: response?.data?.message?.menu,
                status: response?.data?.message?.status,
                email: response?.data?.message?.email,
                membership_fee: response?.data?.message?.membership_fee,
            }
            window.localStorage.setItem('flowState', JSON.stringify(finalObject));
            return finalObject
            if (response?.data?.status) return finalObject
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getSophisticateStatus() {
        try {
            const response = await this._httpServiceSophisticate.get<any>('/CheckStatus');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getUserRegisterPendingFormData() {
        try {
            const response = await this._httpServiceAuth.get<any>('/GetUserRejected');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getUserType() {
        try {
            const response = await this._httpServiceAuth.get<any>('/GetUserType');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getUserData() {
        try {
            const response = await this._httpServiceAuth.get<any>('/SingleUser');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }
    async getVerificationData() {
        try {
            const response = await this._httpServiceAuth.get<any>('/GetOTPTime');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async updateStep(step: number) {
        try {
            const response = await this._httpServiceAuth.put<any>('/SetUserStep', { "step": step });
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async updateAccountType(type: number) {
        try {
            const response = await this._httpServiceAuth.post<any>('/SetUserType', { "user_type": type });
            checkMaintenance(response)
            if (response.data.status) return response?.data
            else {
                reporter(response)
                throw new Error(response?.data?.error?.toString())
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async uploadAttachment(files: any) {
        try {
            const response = await this._httpServiceAuth.post<any>('/Attachment', files);
            checkMaintenance(response)
            if (response.data.status) return response?.data
            else {
                reporter(response)
                throw new Error(response?.data?.error?.toString())
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async updatePhoneNumber(newData: any) {
        try {
            const response = await this._httpServiceAuth.post<any>('/changephonenumber', newData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                throw new Error(response?.data?.error?.toString())
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async sendEmailVerification() {
        try {
            const response = await this._httpServiceAuth.post<VerifyCode>('/VerifyEmailLink');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                throw new Error(response?.data?.error?.toString())
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async checkEmailVerificationCode(code: string) {
        try {
            const response = await this._httpServiceAuth.post<VerifyCode>('/VerifyEmail', { otp_code: code });
            checkMaintenance(response)
            if (response.data.status) return response?.data
            else {
                reporter(response)
                throw new Error(response?.data?.error?.toString())
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async sendPhoneVerification() {
        try {
            const response = await this._httpServiceAuth.post<VerifyCode>('/SendPhoneVerify');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                throw new Error(response?.data?.error?.toString())
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async checkPhoneVerificationCode(code: string) {
        try {
            const response = await this._httpServiceAuth.post<VerifyCode>('/PhoneVerify', { otp_code: code });
            checkMaintenance(response)
            if (response.data.status) return response?.data
            else {
                reporter(response)
                throw new Error(response?.data?.error?.toString())
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async sendUplineVerification() {
        try {
            const response = await this._httpServiceAuth.post<VerifyCode>('/VerifyParentLink');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                throw new Error(response?.data?.error?.toString())
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async checkUplineVerificationCode(code: string) {
        try {
            const response = await this._httpServiceAuth.post<VerifyCode>('/VerifyParent', { otp_code: code });
            checkMaintenance(response)
            if (response.data.status) return response?.data
            else {
                reporter(response)
                throw new Error(response?.data?.error?.toString())
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async verifyWatchVideo(formData: WatchVideoForm) {
        try {
            const response = await this._httpServiceAuth.post<VerifyWatchVideo>('/VerifyWatchVideo', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data
            else {
                reporter(response)
                throw new Error(response?.data?.error?.toString())
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getQuestionVideo() {
        try {
            const response = await this._httpServiceAuth.get<QuestionInWatchVideo>('/VideoQuestions');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getAccountType() {
        try {
            const response = await this._httpServiceAuth.get<AccountType>('/GetTypes ');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getCountries() {
        try {
            const response = await this._httpServiceCountry.get<CountriesList>('/ ');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getAccountTypeDescription() {
        try {
            const response = await this._httpServiceAuth.get<AccountTypeDescription>('/GetTypesDescription ');
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async applicationForm(formData: ApplicationForm) {
        try {
            const response = await this._httpServiceAuth.post<ApplicationUser>('/RegisterPending', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map(e => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

}

export default new WizardService()