import { createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import islandService from '../../services/islandService';
import treasuryService from '../../services/treasuryService';
import AccountContext from './accountContext';
import PrivateContext from './privateContext';
import settingService from '../../services/settingService';
import { useSearchParams } from 'react-router-dom';
const TreasuryContext = createContext<any>({});

export const TreasuryContextProvider = ({ children }: JSX.Element | any) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [currentIslandId, setCurrentIslandId] = useState(null);

  const [currentPage1, setCurrentPage1] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [currentPage3, setCurrentPage3] = useState(1);

  const [treasuresListData, setTreasuresListData] = useState<any>(null);
  async function loadTreasures(
    searchQuery?: string,
    filterStatus?: string,
    filterType?: string,
    offset?: any,
    limit?: number
  ) {
    await treasuryService
      .getAll(searchQuery, filterStatus, filterType, offset, limit)
      .then((data) => setTreasuresListData(data));
  }

  const [ckeditorForceWithdrawModal, setCkeditorForceWithdrawModal] =
    useState(null);
  const [ckeditorClaimWindowModal, setCkeditorClaimWindowModal] =
    useState(null);
  const [ckeditorTopupModal, setCkeditorTopupModal] = useState(null);
  async function loadCkEditorByID(id: number) {
    id === 0 &&
      (await settingService
        .getCkEditorById(id)
        .then((data) => setCkeditorForceWithdrawModal(data)));
    id === 1 &&
      (await settingService
        .getCkEditorById(id)
        .then((data) => setCkeditorTopupModal(data)));
    id === 9 &&
      (await settingService
        .getCkEditorById(id)
        .then((data) => setCkeditorClaimWindowModal(data)));
  }

  const [islandData, setIslandData] = useState<any>(null);
  async function loadsIsland(id: number) {
    await treasuryService.getIsland(id).then((data) => setIslandData(data));
  }

  const [islandsDetailData, setIslandsDetailData] = useState<any>(null);
  async function loadsIslandsDetail(id: number) {
    await treasuryService
      .getIslandDetail(id)
      .then((data) => setIslandsDetailData(data));
  }

  const [listOfTransferableIslandData, setListOfTransferableIslandData] =
    useState<any>(null);
  const [searchParams] = useSearchParams();

  async function loadsListOfTransferableIsland() {
    await treasuryService
      .getListOfTransferableIsland(Number(searchParams.get('id')))
      .then((data) => setListOfTransferableIslandData(data));
  }

  const [approximatlyAvailable, setApproximatlyAvailable] = useState<any>(null);
  async function loadsApproximatlyAvailable(id: number) {
    await treasuryService
      .getApproximatlyAvailable(id)
      .then((data) => setApproximatlyAvailable(data));
  }

  const [locker, setLocker] = useState(false);
  const [modalTopUp, setModalTopUp] = useState(false);
  const [modalClaim, setModalClaim] = useState(false);
  const [modalForceWithdraw, setModalForceWithdraw] = useState(false);

  const [modalTopUpAreYouSure, setModalTopUpAreYouSure] = useState(false);
  const [modalForceWithdrawAreYouSure, setModalForceWithdrawAreYouSure] =
    useState(false);

  async function changeToTx() {
    setTimeout(() => {
      setCurrentTab(2);
    }, 4000);
  }

  const { loadUserInfo } = useContext(AccountContext);

  const [claimAndCompoundSuccessData, setClaimAndCompoundSuccessData] =
    useState(null);
  async function claimAndCompoundHandle(
    claim_amount: number,
    compound_amount: number,
    id: number,
    setClaimAmount: any,
    setCompoundAmound: any
  ) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        treasuryService.claimAndCompound({
          island_id: id,
          claim_amount: claim_amount,
          compound_amount: compound_amount
        }),
        {
          loading: 'Waiting...',
          success: (data: any) => {
            setClaimAmount('');
            setCompoundAmound('');
            setLocker(false);
            setModalClaim(false);
            setTopupSuccessData(data);

            loadUserInfo();
            loadsIsland(id);
            loadsIslandsDetail(id);
            setModalTopUpAreYouSure(false);

            return `Success.`;
          },
          error: (err: any) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  const [topupSuccessData, setTopupSuccessData] = useState(null);
  async function topUpHandle(amount: number, id: number) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        treasuryService.topUp({ island_id: id, amount: amount }),
        {
          loading: 'Waiting...',
          success: (data: any) => {
            setLocker(false);
            // setModalTopUp(false)
            setTopupSuccessData(data);

            loadUserInfo();
            loadsIsland(id);
            loadsIslandsDetail(id);
            setModalTopUpAreYouSure(false);

            return `Success.`;
          },
          error: (err: any) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  const [claimSuccessData, setClaimSuccessData] = useState(null);
  async function claimHandle(amount: number, id: number) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        treasuryService.claim({ island_id: id, amount: amount }),
        {
          loading: 'Waiting...',
          success: (data: any) => {
            setLocker(false);
            // setModalClaim(false)
            setClaimSuccessData(data);

            loadUserInfo();
            loadsIsland(id);
            loadsIslandsDetail(id);

            return `Success.`;
          },
          error: (err: any) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }
  async function claimHandleForType2(amount: number, id: number) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        treasuryService.claim2({ island_id: id, amount: amount }),
        {
          loading: 'Waiting...',
          success: (data: any) => {
            setLocker(false);
            // setModalClaim(false)
            setClaimSuccessData(data);

            loadUserInfo();
            loadsIsland(id);
            loadsIslandsDetail(id);

            return `Success.`;
          },
          error: (err: any) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  const [forceWithdrawSuccessData, setForceWithdrawSuccessData] =
    useState(null);
  async function forceWithdrawHandle(
    amount: number,
    id: number,
    token: number,
    setForceWithdrawAmount: any,
    setWithdraw2FAToken: any
  ) {
    if (!locker) {
      setLocker(true);
      toast.promise(
        treasuryService.forceWithdraw({
          island_id: id,
          amount: amount,
          token: token
        }),
        {
          loading: 'Waiting...',
          success: (data: any) => {
            setLocker(false);
            // setModalForceWithdraw(false)
            setForceWithdrawSuccessData(data);
            loadUserInfo();
            loadsIsland(id);
            loadsIslandsDetail(id);
            setModalForceWithdrawAreYouSure(false);
            setForceWithdrawAmount(false);
            setWithdraw2FAToken(false);

            return `Success.`;
          },
          error: (err: any) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  const [modalIslandTransferToIsland, setModalIslandTransferToIsland] =
    useState(false);
  const [islandTransferToIslandData, setIslandTransferToIslandData] = useState({
    amount: '',
    island_id: Number(currentIslandId),
    to_island_id: ''
  });

  const [
    islandTransferToIslandSuccessData,
    setIslandTransferToIslandSuccessData
  ] = useState(null);
  async function islandTransferToIslandHandle() {
    if (!locker) {
      setLocker(true);
      toast.promise(
        islandService.transferIslandBalance(islandTransferToIslandData),
        {
          loading: 'Preparing...',
          success: (data: any) => {
            setLocker(false);
            setIslandTransferToIslandData({
              amount: '',
              island_id: Number(currentIslandId),
              to_island_id: ''
            });

            // loadDeposits(1)
            // loadBalances()
            // loadAllPayments()
            // loadTransactions()
            // loadWithdraws(1)

            // setModal(false)
            setIslandTransferToIslandSuccessData(data);

            setIslandTransferToIslandData({
              amount: '',
              island_id: Number(currentIslandId),
              to_island_id: ''
            });

            return `Success.`;
          },
          error: (err) => {
            setLocker(false);
            return `${err.message.toString()}`;
          }
        },
        {
          success: {
            duration: 6000,
            icon: (
              <img
                className="toast-logo"
                src={`${window.location.protocol}//${window.location.host}/images/basic-logo.png`}
              />
            )
          }
        }
      );
    }
  }

  const [transactionListSortedBy, setTransactionListSortedBy] =
    useState('desc');

  const [transactionListData, setTransactionListData] = useState<any>(null);
  async function loadTransaction(id: number) {
    await treasuryService
      .getTransactions(id, -1, transactionListSortedBy, currentPage1)
      .then((data) => setTransactionListData(data));
  }

  // TODO
  const [transactionListParticipateData, setTransactionListParticipateData] =
    useState<any>(null);
  async function loadTransactionParticipate(id: number) {
    await treasuryService
      .getTransactions(id, 0, transactionListSortedBy, currentPage2)
      .then((data) => setTransactionListParticipateData(data));
  }

  const [transactionListTopUpData, setTransactionListTopUpData] =
    useState<any>(null);
  async function loadTransactionTopUp() {
    // await treasuryService.getTransactions(currentIslandId, 1, transactionListSortedBy).then(data => setTransactionListTopUpData(data))
  }

  const [transactionListClaimData, setTransactionListClaimData] =
    useState<any>(null);
  async function loadTransactionClaim(id: number) {
    await treasuryService
      .getTransactions(id, 2, transactionListSortedBy, currentPage3)
      .then((data) => setTransactionListClaimData(data));
  }

  const [
    transactionListWithdrawForceData,
    setTransactionListWithdrawForceData
  ] = useState<any>(null);
  async function loadTransactionWithdrawForce() {
    // await treasuryService.getTransactions(currentIslandId, 3, transactionListSortedBy).then(data => setTransactionListWithdrawForceData(data))
  }

  const [transactionListRefundData, setTransactionListRefundData] =
    useState<any>(null);
  async function loadTransactionRefund() {
    // await treasuryService.getTransactions(currentIslandId, 4, transactionListSortedBy).then(data => setTransactionListRefundData(data))
  }
  ///

  const [crewData, setCrewData] = useState<any>(null);
  async function loadCrew(id: number) {
    await treasuryService.getCrew(id).then((data) => setCrewData(data));
  }

  const [profitChartDailyData, setProfitChartDailyData] = useState<any>(null);
  const [profitChartMonthlyData, setProfitChartMonthlyData] =
    useState<any>(null);
  async function loadProfitChart(id: number, type: string) {
    type === 'monthly' &&
      (await treasuryService
        .getProfitChart(id, type)
        .then((data) => setProfitChartDailyData(data)));
    type === 'yearly' &&
      (await treasuryService
        .getProfitChart(id, type)
        .then((data) => setProfitChartMonthlyData(data)));
  }

  const [transactionChartDailyData, setTransactionChartDailyData] =
    useState<any>(null);
  const [transactionChartMonthlyData, setTransactionChartMonthlyData] =
    useState<any>(null);
  async function loadTransactionChart(id: number, type: 'yearly' | 'monthly') {
    type === 'monthly' &&
      (await treasuryService
        .getTxChart(id, type)
        .then((data) => setTransactionChartDailyData(data)));
    type === 'yearly' &&
      (await treasuryService
        .getTxChart(id, type)
        .then((data) => setTransactionChartMonthlyData(data)));
  }

  const [islandClaimWindow, setIslandClaimWindow] = useState<any>(null);
  async function loadIslandClaimWithdraw(id: number) {
    if (Number(currentIslandId) !== -1) {
      await islandService
        .getCheckClaimWindow(id)
        .then((data) => setIslandClaimWindow(data));
    }
  }

  const { flowState } = useContext(PrivateContext);

  // useEffect(() => {
  //   if (flowState.step === 17) {
  //     loadTreasures()
  //   }
  // }, [flowState])

  useEffect(() => {
    if (currentIslandId) {
      loadTransaction(currentIslandId);
      loadTransactionParticipate(currentIslandId);
      loadIslandClaimWithdraw(currentIslandId);
      loadTransactionClaim(currentIslandId);
    }
  }, [transactionListSortedBy, currentIslandId]);

  const { userInfo } = useContext(AccountContext);
  useEffect(() => {
    if (flowState.step === 17 && userInfo && userInfo?.membershipStatus !== 2) {
      if (currentIslandId !== null) {
        loadTransaction(currentIslandId);
        loadCrew(currentIslandId);
        loadsIslandsDetail(currentIslandId);
        loadsListOfTransferableIsland();
        loadsApproximatlyAvailable(currentIslandId);
        loadsIsland(currentIslandId);
      }
    }
  }, [currentIslandId, flowState]);

  const context = {
    modalIslandTransferToIsland,
    setModalIslandTransferToIsland,
    islandTransferToIslandData,
    setIslandTransferToIslandData,
    islandTransferToIslandSuccessData,
    setIslandTransferToIslandSuccessData,
    islandTransferToIslandHandle,

    islandClaimWindow,
    setIslandClaimWindow,
    loadIslandClaimWithdraw,
    currentTab,
    setCurrentTab,

    currentIslandId,
    setCurrentIslandId,

    treasuresListData,
    loadTreasures,
    islandData,
    loadsIsland,

    setTransactionListSortedBy,
    transactionListData,
    loadTransaction,
    transactionListParticipateData,
    loadTransactionParticipate,
    transactionListTopUpData,
    loadTransactionTopUp,
    transactionListClaimData,
    loadTransactionClaim,
    transactionListWithdrawForceData,
    loadTransactionWithdrawForce,
    transactionListRefundData,
    loadTransactionRefund,

    crewData,
    loadCrew,
    ckeditorForceWithdrawModal,
    loadCkEditorByID,
    profitChartDailyData,
    profitChartMonthlyData,
    loadProfitChart,

    transactionChartDailyData,
    transactionChartMonthlyData,
    loadTransactionChart,

    claimAndCompoundHandle,
    claimAndCompoundSuccessData,
    setClaimAndCompoundSuccessData,
    islandsDetailData,
    loadsIslandsDetail,
    approximatlyAvailable,
    loadsApproximatlyAvailable,
    modalTopUp,
    setModalTopUp,
    modalClaim,
    setModalClaim,
    modalForceWithdraw,
    setModalForceWithdraw,
    modalTopUpAreYouSure,
    setModalTopUpAreYouSure,
    modalForceWithdrawAreYouSure,
    setModalForceWithdrawAreYouSure,
    topUpHandle,
    claimHandle,
    claimHandleForType2,
    forceWithdrawHandle,

    currentPage1,
    setCurrentPage1,
    currentPage2,
    setCurrentPage2,
    currentPage3,
    setCurrentPage3,
    changeToTx,
    forceWithdrawSuccessData,
    setForceWithdrawSuccessData,
    topupSuccessData,
    setTopupSuccessData,
    ckeditorTopupModal,
    setCkeditorTopupModal,
    claimSuccessData,
    setClaimSuccessData,
    ckeditorClaimWindowModal,
    listOfTransferableIslandData,
    loadsListOfTransferableIsland
  };

  return (
    <TreasuryContext.Provider value={context}>
      {children}
    </TreasuryContext.Provider>
  );
};

export default TreasuryContext;
