import { useContext } from 'react';
import PrivateContext from '../context/private/privateContext';
import SidebarContext from '../context/private/sidebarContext';
import {
  MenuItem,
  MenuItemWithSub,
  MenuItemWithSub2,
  MenuItemWithSub3,
  MenuItemWithSub4,
  MenuItemWithSubReusable
} from './MenuItem';
import dictionary from '../locals/aside.json';
import AccountContext from '../context/private/accountContext';
import { Fa500Px, FaAccessibleIcon, FaQuora } from 'react-icons/fa';

const Menu = ({ step }: { step: number }): JSX.Element => {
  const { logoutHandle } = useContext(SidebarContext);
  const { userInfo } = useContext(AccountContext);
  const { setIsOpen, flowState, sophisticateStatus } =
    useContext(PrivateContext);

  if (flowState?.step === 20)
    return (
      <>
        <MenuItem title={dictionary['MENU-13']} path="/" icon="icon-account" />
        <MenuItem
          title={dictionary['MENU-8']}
          path="/login"
          onClick={logoutHandle}
          icon="icon-signout"
        />
      </>
    );

  if (sophisticateStatus !== null) {
    if (step === 7)
      if (
        sophisticateStatus?.isActive === 1 &&
        sophisticateStatus?.status === 0
      ) {
        return (
          <>
            <MenuItem
              title={dictionary['MENU-0']}
              onClick={() => setIsOpen(false)}
              path="/"
              icon="icon-my-bos"
            />
            <MenuItem
              title={dictionary['MENU-8']}
              path="/login"
              onClick={logoutHandle}
              icon="icon-signout"
            />
          </>
        );
      } else {
        if (userInfo && userInfo?.membershipStatus === 2) {
          return (
            <>
              <MenuItem
                title={dictionary['MENU-10']}
                onClick={() => setIsOpen(false)}
                path="/"
                icon="icon-wizard"
              />
              <MenuItem
                title={dictionary['MENU-11']}
                onClick={() => setIsOpen(false)}
                path="/financial-account"
                icon="icon-account"
              />
              <MenuItem
                title={dictionary['MENU-6']}
                onClick={() => setIsOpen(false)}
                path="/profile"
                icon="icon-account"
              />

              <MenuItem
                title={dictionary['MENU-8']}
                path="/login"
                onClick={logoutHandle}
                icon="icon-signout"
              />
            </>
          );
        } else {
          return (
            <>
              <MenuItem
                title={dictionary['MENU-1']}
                onClick={() => setIsOpen(false)}
                path="/"
                icon="icon-map"
              />
              {userInfo?.is_action_limit === 0 && (
                <MenuItem
                  title={dictionary['MENU-2']}
                  onClick={() => setIsOpen(false)}
                  path="/islands"
                  icon="icon-island"
                />
              )}
              <MenuItem
                title={dictionary['MENU-3']}
                onClick={() => setIsOpen(false)}
                path="/my-treasures"
                icon="icon-treasury"
              />
              {userInfo?.is_action_limit === 0 && (
                <MenuItemWithSub4
                  title={dictionary['MENU-24']}
                  path={{
                    myStrategy: '/my-strategy',
                    blueprint: '/wealth-blueprint',
                    strategy: '/treasury-strategy',
                    bosBeacons: '/bos-beacons'
                  }}
                  icon="icon-blub"
                />
              )}

              {/* <MenuItem
                title={dictionary['MENU-5']}
                onClick={() => setIsOpen(false)}
                path="/my-wallet"
                icon="icon-wallet"
              /> */}
              <MenuItemWithSub3
                title={dictionary['MENU-5']}
                path={{
                  myWallet: '/my-wallet',
                  treasury: '/treasury-balance'
                }}
                icon="icon-wallet"
              />
              <MenuItemWithSub
                title={dictionary['MENU-6']}
                path={{
                  profile: '/profile',
                  account: '/financial-account',
                  notification: '/notifications',
                  logs: '/logs'
                }}
                icon="icon-account"
              />

              {userInfo?.is_action_limit === 0 && (
                <MenuItem
                  title={dictionary['MENU-4']}
                  onClick={() => setIsOpen(false)}
                  path="/bos-crew"
                  icon="icon-crew"
                />
              )}

              {userInfo?.is_action_limit === 0 && (
                <MenuItemWithSub2
                  title={dictionary['MENU-7']}
                  onClick={() => setIsOpen(false)}
                  path={{
                    aboutus: '/aboutus',
                    updates: '/updates',
                    meeting: '/meetings',
                    qa: '/qa',
                    webinars: '/webinars',
                    socials: '/socials',
                    academy: '/academy',
                    service: '/service',
                    imbl: '/imbl',
                    recommendations: '/recommendations',
                    strategySession: '/strategy-session',
                    nevis: '/nevis-2025',
                    nevisRregistration: '/nevis-2025-registration',
                    offshore: '/offshore-companies',
                    bosStories: '/bos-stories',
                    offshoreBanking: '/offshore-banking',
                    cayee: '/caye',
                    chase: '/chase',
                    debitcard: '/debitcard',
                    lifestyle: '/lifestyle',
                    events: '/events',
                    shop: '/shop',
                    charity: '/charity',
                    // helpdesk: '/helpdesk',
                    // tutorials: '/tutorials',
                    // dashboardFaqs: '/dashboard-faqs',
                    // dashboardTutorials: '/dashboard-tutorials',
                    // cryptoTutorials: '/crypto-tutorials'

                    bosFaqs: '/bos-faqs',
                    bosVideoArchive: '/bos-video-archive'
                  }}
                  icon="icon-my-bos"
                />
              )}
              {userInfo?.is_action_limit === 0 && (
                <MenuItemWithSubReusable
                  mainPath="/helpdesk"
                  title={dictionary['MENU-7-sub-12']}
                  subItems={[
                    {
                      path: '/tutorials',
                      icon: <Fa500Px />,
                      label: dictionary['MENU-7-sub-11'],
                      paths: {
                        tutorials: '/tutorials',
                        dashboardFaqs: '/dashboard-faqs',

                        depositTutorials: '/deposit-tutorials',
                        depositCryptoTutorials: '/crypto-deposits',
                        dashboardTutorials: '/dashboard-tutorials',
                        cryptoTutorials: '/crypto-tutorials',
                        depositBankTutorials: '/bank-deposits'
                      }
                    },
                    {
                      path: '/dashboard-faqs',
                      icon: <FaQuora />,
                      label: dictionary['MENU-7-sub-12-sub-1']
                    },
                    {
                      path: '/faq-system',
                      icon: <FaQuora />,
                      isLink: true,
                      label: dictionary['MENU-7-sub-12-sub-2']
                    }
                  ]}
                  // subItems={{
                  //   aboutus: '/tutorials',
                  //   dashboardFaqs: '/dashboard-faqs'
                  // }}
                  icon="icon-bos-help-desk"
                />
              )}
              <MenuItem
                title={dictionary['MENU-8']}
                path="/login"
                onClick={logoutHandle}
                icon="icon-signout"
              />
            </>
          );
        }
      }

    if (step === 6)
      return (
        <>
          <MenuItem title={dictionary['MENU-9']} path="/" icon="icon-wizard" />

          <MenuItem
            title={dictionary['MENU-8']}
            path="/login"
            onClick={logoutHandle}
            icon="icon-signout"
          />
        </>
      );

    if (step === 5)
      return (
        <>
          <MenuItem title={dictionary['MENU-10']} path="/" icon="icon-wizard" />
          <MenuItem
            title={dictionary['MENU-8']}
            path="/login"
            onClick={logoutHandle}
            icon="icon-signout"
          />
        </>
      );

    if (step === 4)
      return (
        <>
          <MenuItem title={dictionary['MENU-11']} path="/" icon="icon-wizard" />
          <MenuItem
            title={dictionary['MENU-8']}
            path="/login"
            onClick={logoutHandle}
            icon="icon-signout"
          />
        </>
      );

    if (step === 3)
      return (
        <>
          <MenuItem title={dictionary['MENU-12']} path="/" icon="icon-status" />
          <MenuItem
            title={dictionary['MENU-8']}
            path="/login"
            onClick={logoutHandle}
            icon="icon-signout"
          />
        </>
      );

    if (step === 2)
      return (
        <>
          <MenuItem
            title={dictionary['MENU-13']}
            path="/"
            icon="icon-account"
          />
          <MenuItem
            target="_blank"
            title={dictionary['MENU-13-1']}
            path={'/faq-system?topic=2'}
            icon="active-icon-bos-help-desk"
          />
          <MenuItem
            title={dictionary['MENU-8']}
            path="/login"
            onClick={logoutHandle}
            icon="icon-signout"
          />
        </>
      );

    if (step === 1)
      return (
        <>
          <MenuItem
            title={dictionary['MENU-14']}
            path="/"
            icon="icon-welcome"
          />
          <MenuItem
            title={dictionary['MENU-8']}
            path="/login"
            onClick={logoutHandle}
            icon="icon-signout"
          />
        </>
      );
  }

  return (
    <>
      <MenuItem title={dictionary['MENU-15']} path="/" icon="icon-identity" />
      <MenuItem
        title={dictionary['MENU-8']}
        path="/login"
        onClick={logoutHandle}
        icon="icon-signout"
      />
    </>
  );
};
export default Menu;
