import { Link, useLocation } from 'react-router-dom';
import {
  Item,
  ItemWithSub,
  MenuItemWithSubReusableProps
} from '../types/sidebarTypes';

import { useContext } from 'react';
import { BsCalendarEventFill, BsCreditCardFill } from 'react-icons/bs';
import {
  FaHandsHelping,
  FaQuora,
  FaServicestack,
  FaUserAlt,
  FaWallet
} from 'react-icons/fa';
import { GiEarthAsiaOceania, GiShoppingBag } from 'react-icons/gi';
import { HiAcademicCap } from 'react-icons/hi';
import {
  IoDiamondSharp,
  IoLogoTableau,
  IoShareSocialSharp
} from 'react-icons/io5';
import {
  MdCastForEducation,
  MdNotificationsActive,
  MdOutlineHomeRepairService,
  MdOutlineTipsAndUpdates
} from 'react-icons/md';
import { DiDigitalOcean, DiWebplatform } from 'react-icons/di';
import {
  RiHomeHeartLine,
  RiLightbulbFlashFill,
  RiMoneyDollarCircleFill
} from 'react-icons/ri';
import { SiGooglemeet, SiHelpscout, SiStylelint } from 'react-icons/si';
import PrivateContext from '../context/private/privateContext';
import { Flex } from '../styles/Styles';
import dictionary from '../locals/aside.json';
import { IoIosListBox } from 'react-icons/io';
import AccountContext from '../context/private/accountContext';

const MenuItem = ({
  title,
  path,
  icon,
  onClick,
  target = '_self'
}: Item): JSX.Element => {
  const location = useLocation();
  return (
    <Link
      target={target}
      onClick={onClick}
      to={path}
      className={
        location.pathname.toLowerCase() === path
          ? 'sidebar-link active'
          : 'sidebar-link'
      }
    >
      <div className="sidebar-options">
        <span className={'icon ' + icon}></span>
        <p>{title}</p>
      </div>
    </Link>
  );
};

const MenuItemWithSub = ({ title, path, icon }: ItemWithSub): JSX.Element => {
  const { setIsOpen } = useContext(PrivateContext);
  const location = useLocation();
  const { userInfo } = useContext(AccountContext);

  const currentPath = location.pathname.toLowerCase();
  const checkHover =
    currentPath === path.profile ||
    currentPath === path.account ||
    currentPath === path.notification ||
    currentPath === path.logs;
  return (
    <Link
      to={path.profile}
      className={checkHover ? 'sidebar-link active-sub' : 'sidebar-link'}
    >
      <div className="sidebar-options">
        <span className={'icon ' + icon}></span>
        <p>{title}</p>
      </div>

      {checkHover && (
        <>
          <Link
            to="/profile"
            className="sidebar-link sub"
            onClick={() => {
              window.scrollTo(0, 0);
              setIsOpen(false);
            }}
          >
            <div
              className={`sidebar-option-${
                currentPath === path.profile ? 'f' : 's'
              }-index`}
            >
              <FaUserAlt />
              {dictionary['MENU-6-sub-1']}
            </div>
          </Link>
          <Link
            to="/financial-account"
            className="sidebar-link sub"
            onClick={() => {
              window.scrollTo(0, 0);
              setIsOpen(false);
            }}
          >
            <div
              className={`sidebar-option-${
                currentPath === path.account ? 'f' : 's'
              }-index`}
            >
              <RiMoneyDollarCircleFill />
              {dictionary['MENU-6-sub-2']}
            </div>
          </Link>
          <Link
            to="/notifications"
            className="sidebar-link sub"
            onClick={() => {
              window.scrollTo(0, 0);
              setIsOpen(false);
            }}
          >
            <div
              className={`sidebar-option-${
                currentPath === path.notification ? 'f' : 's'
              }-index`}
            >
              <MdNotificationsActive />
              {dictionary['MENU-6-sub-3']}
            </div>
          </Link>
          {userInfo?.is_action_limit === 0 && (
            <Link
              to="/logs"
              className="sidebar-link sub"
              onClick={() => {
                window.scrollTo(0, 0);
                setIsOpen(false);
              }}
            >
              <div
                className={`sidebar-option-${
                  currentPath === path.logs ? 'f' : 's'
                }-index`}
              >
                <IoIosListBox />
                {dictionary['MENU-6-sub-4']}
              </div>
            </Link>
          )}
        </>
      )}
    </Link>
  );
};

const MenuItemWithSub2 = ({ title, path, icon }: any): JSX.Element => {
  const { setIsOpen } = useContext(PrivateContext);
  const location = useLocation();
  const currentPath = location.pathname.toLowerCase();
  const checkHover =
    currentPath === path.webinars ||
    currentPath === path.qa ||
    currentPath === path.meeting ||
    currentPath === path.aboutus ||
    currentPath === path.updates ||
    currentPath === path.socials ||
    currentPath === path.academy ||
    currentPath === path.offshore ||
    currentPath === path.offshoreBanking ||
    currentPath === path.cayee ||
    currentPath === path.chase ||
    currentPath === path.debitcard ||
    currentPath === path.lifestyle ||
    currentPath === path.events ||
    currentPath === path.shop ||
    currentPath === path.charity ||
    currentPath === path.helpdesk ||
    currentPath === path.tutorials ||
    currentPath === path.dashboardFaqs ||
    currentPath === path.bosFaqs ||
    currentPath === path.bosStories ||
    currentPath === path.bosVideoArchive ||
    currentPath === path.dashboardTutorials ||
    currentPath === path.cryptoTutorials ||
    currentPath === path.depositTutorials ||
    currentPath === path.depositCryptoTutorials ||
    currentPath === path.depositBankTutorials ||
    currentPath === path.imbl ||
    currentPath === path.recommendations ||
    currentPath === path.strategySession ||
    currentPath === path.nevis ||
    currentPath === path.nevisRregistration ||
    currentPath === path.service;
  return (
    <Link
      to={path.aboutus}
      className={checkHover ? 'sidebar-link active-sub' : 'sidebar-link'}
    >
      <div className="sidebar-options">
        <span className={'icon ' + icon}></span>
        <p>{title}</p>
      </div>

      {checkHover && (
        <div>
          <Link
            to="/aboutus"
            className="sidebar-link sub aboutus"
            onClick={() => {
              setIsOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            <Flex direction={'col'}>
              <div
                className={`sidebar-option-${
                  currentPath === path.aboutus ? 'f' : 's'
                }-index`}
              >
                <RiHomeHeartLine />
                {dictionary['MENU-7-sub-1']}
              </div>
              {(currentPath === path.aboutus ||
                currentPath === path.bosFaqs ||
                currentPath === path.bosStories ||
                currentPath === path.bosVideoArchive) && (
                <div>
                  <Link
                    to="/bos-stories"
                    className="submenu-3rd sidebar-link sub bos-stories"
                    onClick={() => {
                      setIsOpen(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div
                      className={`sidebar-option-${
                        currentPath === path.bosStories ? 'f' : 's'
                      }-index`}
                    >
                      <FaQuora />
                      {dictionary['MENU-7-sub-1-sub-0']}
                    </div>
                  </Link>
                  <Link
                    to="/bos-faqs"
                    className="submenu-3rd sidebar-link sub bos-faqs"
                    onClick={() => {
                      setIsOpen(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div
                      className={`sidebar-option-${
                        currentPath === path.bosFaqs ? 'f' : 's'
                      }-index`}
                    >
                      <FaQuora />
                      {dictionary['MENU-7-sub-1-sub-1']}
                    </div>
                  </Link>
                  <Link
                    to="/bos-video-archive"
                    className="submenu-3rd sidebar-link sub video-archive"
                    onClick={() => {
                      setIsOpen(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div
                      className={`sidebar-option-${
                        currentPath === path.bosVideoArchive ? 'f' : 's'
                      }-index`}
                    >
                      <FaQuora />
                      {dictionary['MENU-7-sub-1-sub-2']}
                    </div>
                  </Link>
                </div>
              )}
            </Flex>
          </Link>

          <Link
            to="/updates"
            className="sidebar-link sub updates"
            onClick={() => {
              setIsOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            <div
              className={`sidebar-option-${
                currentPath === path.updates ? 'f' : 's'
              }-index`}
            >
              <MdOutlineTipsAndUpdates />
              {dictionary['MENU-7-sub-2']}
            </div>
          </Link>

          <Link
            to="/meetings"
            className="sidebar-link sub meetings"
            onClick={() => {
              setIsOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            <Flex direction={'col'}>
              <div
                className={`sidebar-option-${
                  currentPath === path.meeting ? 'f' : 's'
                }-index`}
              >
                <SiGooglemeet />
                {dictionary['MENU-7-sub-3']}
              </div>
              {(currentPath === path.meeting ||
                currentPath === path.qa ||
                currentPath === path.webinars) && (
                <div>
                  <Link
                    to="/qa"
                    className="submenu-3rd sidebar-link sub bosqas"
                    onClick={() => {
                      setIsOpen(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div
                      className={`sidebar-option-${
                        currentPath === path.qa ? 'f' : 's'
                      }-index`}
                    >
                      <FaQuora />
                      {dictionary['MENU-7-sub-3-sub-1']}
                    </div>
                  </Link>
                  <Link
                    to="/webinars"
                    className="submenu-3rd sidebar-link sub webinars"
                    onClick={() => {
                      setIsOpen(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div
                      className={`sidebar-option-${
                        currentPath === path.webinars ? 'f' : 's'
                      }-index`}
                    >
                      <DiWebplatform />
                      {dictionary['MENU-7-sub-3-sub-2']}
                    </div>
                  </Link>
                </div>
              )}
            </Flex>
          </Link>

          <Link
            to="/socials"
            className="sidebar-link sub socials"
            onClick={() => {
              setIsOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            <div
              className={`sidebar-option-${
                currentPath === path.socials ? 'f' : 's'
              }-index`}
            >
              <IoShareSocialSharp />
              {dictionary['MENU-7-sub-4']}
            </div>
          </Link>
          <Link
            to="/academy"
            className="sidebar-link sub academy"
            onClick={() => {
              setIsOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            <div
              className={`sidebar-option-${
                currentPath === path.academy ? 'f' : 's'
              }-index`}
            >
              <HiAcademicCap />
              {dictionary['MENU-7-sub-5']}
            </div>
          </Link>
          <Link
            to="/service"
            className="sidebar-link sub service"
            onClick={() => {
              setIsOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            <Flex direction={'col'}>
              <div
                className={`sidebar-option-${
                  currentPath === path.service ? 'f' : 's'
                }-index`}
              >
                <MdOutlineHomeRepairService />
                {dictionary['MENU-7-sub-6']}
              </div>
              {(currentPath === path.service ||
                currentPath === path.offshore ||
                currentPath === path.imbl ||
                currentPath === path.recommendations ||
                currentPath === path.strategySession ||
                currentPath === path.offshoreBanking ||
                currentPath === path.cayee ||
                currentPath === path.chase ||
                currentPath === path.debitcard) && (
                <div>
                  <Link
                    to="/offshore-companies"
                    className="submenu-3rd sidebar-link sub offshore-companies"
                    onClick={() => {
                      setIsOpen(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div
                      className={`sidebar-option-${
                        currentPath === path.offshore ? 'f' : 's'
                      }-index`}
                    >
                      <GiEarthAsiaOceania />
                      <div>
                        {dictionary['MENU-7-sub-6-sub-1']}
                        <Flex direction={'col'}>
                          <Link
                            to="/recommendations"
                            className="submenu-3rd sidebar-link sub recommendations"
                            onClick={() => {
                              setIsOpen(false);
                              window.scrollTo(0, 0);
                            }}
                          >
                            <div
                              className={`sidebar-option-${
                                currentPath === path.recommendations ? 'f' : 's'
                              }-index`}
                            >
                              <GiEarthAsiaOceania />
                              {dictionary['MENU-17']}
                            </div>
                          </Link>
                          <Link
                            to="/strategy-session"
                            className="submenu-3rd sidebar-link sub strategy-session"
                            onClick={() => {
                              setIsOpen(false);
                              window.scrollTo(0, 0);
                            }}
                          >
                            <div
                              className={`sidebar-option-${
                                currentPath === path.strategySession ? 'f' : 's'
                              }-index`}
                            >
                              <GiEarthAsiaOceania />
                              {dictionary['MENU-18']}
                            </div>
                          </Link>
                        </Flex>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="/offshore-banking"
                    className="submenu-3rd sidebar-link sub offshore-banking"
                    onClick={() => {
                      setIsOpen(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div
                      className={`sidebar-option-${
                        currentPath === path.offshoreBanking ? 'f' : 's'
                      }-index`}
                    >
                      <GiEarthAsiaOceania />
                      <div>
                        {dictionary['MENU-7-sub-6-sub-2']}
                        <Flex direction={'col'}>
                          <Link
                            to="/imbl"
                            className="submenu-3rd sidebar-link sub imbl"
                            onClick={() => {
                              setIsOpen(false);
                              window.scrollTo(0, 0);
                            }}
                          >
                            <div
                              className={`sidebar-option-${
                                currentPath === path.imbl ? 'f' : 's'
                              }-index`}
                            >
                              <GiEarthAsiaOceania />
                              {dictionary['MENU-16']}
                            </div>
                          </Link>
                          <Link
                            to="/caye"
                            className="submenu-3rd sidebar-link sub caye"
                            onClick={() => {
                              setIsOpen(false);
                              window.scrollTo(0, 0);
                            }}
                          >
                            <div
                              className={`sidebar-option-${
                                currentPath === path.cayee ? 'f' : 's'
                              }-index`}
                            >
                              <GiEarthAsiaOceania />
                              {dictionary['MENU-21']}
                            </div>
                          </Link>
                          <Link
                            to="/chase"
                            className="submenu-3rd sidebar-link sub chase"
                            onClick={() => {
                              setIsOpen(false);
                              window.scrollTo(0, 0);
                            }}
                          >
                            <div
                              className={`sidebar-option-${
                                currentPath === path.chase ? 'f' : 's'
                              }-index`}
                            >
                              <GiEarthAsiaOceania />
                              {dictionary['MENU-23']}
                            </div>
                          </Link>
                          <Link
                            to="/debitcard"
                            className="submenu-3rd sidebar-link sub debitcard"
                            onClick={() => {
                              setIsOpen(false);
                              window.scrollTo(0, 0);
                            }}
                          >
                            <div
                              className={`sidebar-option-${
                                currentPath === path.debitcard ? 'f' : 's'
                              }-index`}
                            >
                              <GiEarthAsiaOceania />
                              {dictionary['MENU-22']}
                            </div>
                          </Link>
                        </Flex>
                      </div>
                    </div>
                  </Link>
                </div>
              )}
            </Flex>
          </Link>
          <Link
            to="/lifestyle"
            className="sidebar-link sub lifestyle"
            onClick={() => {
              setIsOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            <div
              className={`sidebar-option-${
                currentPath === path.lifestyle ? 'f' : 's'
              }-index`}
            >
              <SiStylelint />
              {dictionary['MENU-7-sub-7']}
            </div>
          </Link>

          {/* Menu */}
          <Link
            to="/events"
            className="sidebar-link sub events"
            onClick={() => {
              setIsOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            <Flex direction={'col'}>
              <div
                className={`sidebar-option-${
                  currentPath === path.events ? 'f' : 's'
                }-index`}
              >
                <BsCalendarEventFill />
                {dictionary['MENU-7-sub-8']}
              </div>
              {(currentPath === path.events ||
                currentPath === path.nevis ||
                currentPath === path.nevisRregistration) && (
                <div>
                  <Link
                    to="/nevis-2025"
                    className="submenu-3rd sidebar-link sub nevis-display"
                    onClick={() => {
                      setIsOpen(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div
                      className={`sidebar-option-${
                        currentPath === path.nevis ? 'f' : 's'
                      }-index`}
                    >
                      <FaQuora />
                      {dictionary['MENU-19']}
                    </div>
                  </Link>
                  <Link
                    to="/nevis-2025-registration"
                    className="submenu-3rd sidebar-link sub nevis-registration-display"
                    onClick={() => {
                      setIsOpen(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div
                      className={`sidebar-option-${
                        currentPath === path.nevisRregistration ? 'f' : 's'
                      }-index`}
                    >
                      <FaQuora />
                      {dictionary['MENU-20']}
                    </div>
                  </Link>
                </div>
              )}
            </Flex>
          </Link>

          <Link
            to="/shop"
            className="sidebar-link sub shop"
            onClick={() => {
              setIsOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            <div
              className={`sidebar-option-${
                currentPath === path.shop ? 'f' : 's'
              }-index`}
            >
              <GiShoppingBag />
              {dictionary['MENU-7-sub-9']}
            </div>
          </Link>
          <Link
            to="/charity"
            className="sidebar-link sub charity"
            onClick={() => {
              setIsOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            <div
              className={`sidebar-option-${
                currentPath === path.charity ? 'f' : 's'
              }-index`}
            >
              <FaHandsHelping />
              {dictionary['MENU-7-sub-10']}
            </div>
          </Link>
          {/* <Link
            to="/tutorials"
            className="sidebar-link sub tutorials"
            onClick={() => {
              setIsOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            <Flex direction={'col'}>
              <div
                className={`sidebar-option-${
                  currentPath === path.tutorials ? 'f' : 's'
                }-index`}
              >
                <MdCastForEducation />
                {dictionary['MENU-7-sub-11']}
              </div>
              {(currentPath === path.tutorials ||
                currentPath === path.dashboardTutorials ||
                currentPath === path.depositTutorials ||
                currentPath === path.depositCryptoTutorials ||
                currentPath === path.depositBankTutorials ||
                currentPath === path.cryptoTutorials) && (
                <div>
                  <Link
                    to="/dashboard-tutorials"
                    className="submenu-3rd sidebar-link sub db-tutorials"
                    onClick={() => {
                      setIsOpen(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div
                      className={`sidebar-option-${
                        currentPath === path.dashboardTutorials ? 'f' : 's'
                      }-index`}
                    >
                      <FaQuora />
                      {dictionary['MENU-7-sub-11-sub-1']}
                    </div>
                  </Link>
                  <Link
                    to="/crypto-tutorials"
                    className="submenu-3rd sidebar-link sub cr-tutorials"
                    onClick={() => {
                      setIsOpen(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div
                      className={`sidebar-option-${
                        currentPath === path.cryptoTutorials ? 'f' : 's'
                      }-index`}
                    >
                      <FaQuora />
                      {dictionary['MENU-7-sub-11-sub-2']}
                    </div>
                  </Link>
                  <Link
                    to="/deposit-tutorials"
                    className="submenu-3rd sidebar-link sub dep-tutorials"
                    onClick={() => {
                      setIsOpen(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div
                      className={` flex flex-col sidebar-option-${
                        currentPath === path.depositTutorials ||
                        currentPath === path.depositCryptoTutorials ||
                        currentPath === path.depositBankTutorials
                          ? 'f'
                          : 's'
                      }-index`}
                    >
                      <span className="flex gap-1 items-center">
                        <FaQuora />
                        {dictionary['MENU-7-sub-11-sub-3']}
                      </span>

                      <div className="pl-4 mt-2 space-y-2">
                        <Link
                          to={path.depositCryptoTutorials}
                          className="block text-md text-gray-600 hover:text-blue-500 transition-colors duration-200"
                          onClick={() => {
                            setIsOpen(false);
                            window.scrollTo(0, 0);
                          }}
                        >
                          <div className="flex items-center space-x-2">
                            <FaQuora className="text-md" />
                            <span>
                              {dictionary['MENU-7-sub-11-sub-3-sub-1']}
                            </span>
                          </div>
                        </Link>
                        <Link
                          to={path.depositBankTutorials}
                          className="block text-md text-gray-600 hover:text-blue-500 transition-colors duration-200"
                          onClick={() => {
                            setIsOpen(false);
                            window.scrollTo(0, 0);
                          }}
                        >
                          <div className="flex items-center space-x-2">
                            <FaQuora className="text-md" />
                            <span>
                              {dictionary['MENU-7-sub-11-sub-3-sub-2']}
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Link>
                </div>
              )}
            </Flex>
          </Link> */}
          {/* <Link
            to="/helpdesk"
            className="sidebar-link sub helpdesk"
            onClick={() => {
              setIsOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            <Flex direction={'col'}>
              <div
                className={`sidebar-option-${
                  currentPath === path.helpdesk ? 'f' : 's'
                }-index`}
              >
                <SiHelpscout />
                {dictionary['MENU-7-sub-12']}
              </div>
              {(currentPath === path.helpdesk ||
                currentPath === path.dashboardFaqs) && (
                <div>
                  <Link
                    to="/dashboard-faqs"
                    className="submenu-3rd sidebar-link sub db-faqs"
                    onClick={() => {
                      setIsOpen(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div
                      className={`sidebar-option-${
                        currentPath === path.dashboardFaqs ? 'f' : 's'
                      }-index`}
                    >
                      <FaQuora />
                      {dictionary['MENU-7-sub-12-sub-1']}
                    </div>
                  </Link>
                </div>
              )}
            </Flex>
          </Link> */}
        </div>
      )}
    </Link>
  );
};

const MenuItemWithSub3 = ({ title, path, icon }: any): JSX.Element => {
  const { setIsOpen } = useContext(PrivateContext);
  const location = useLocation();
  const currentPath = location.pathname.toLowerCase();
  const checkHover =
    currentPath === path.myWallet || currentPath === path.treasury;
  return (
    <Link
      to={path.myWallet}
      className={checkHover ? 'sidebar-link active-sub' : 'sidebar-link'}
    >
      <div className="sidebar-options">
        <span className={'icon active-' + icon}></span>
        <p>{title}</p>
      </div>

      {checkHover && (
        <>
          <Link
            to="/my-wallet"
            className="sidebar-link sub"
            onClick={() => {
              window.scrollTo(0, 0);
              setIsOpen(false);
            }}
          >
            <div
              className={`sidebar-option-${
                currentPath === path.myWallet ? 'f' : 's'
              }-index`}
            >
              <FaWallet />
              {dictionary['MENU-5-sub-1']}
            </div>
          </Link>
          <Link
            to="/treasury-balance"
            className="sidebar-link sub"
            onClick={() => {
              window.scrollTo(0, 0);
              setIsOpen(false);
            }}
          >
            <div
              className={`sidebar-option-${
                currentPath === path.treasury ? 'f' : 's'
              }-index`}
            >
              <IoDiamondSharp />
              {dictionary['MENU-5-sub-2']}
            </div>
          </Link>
        </>
      )}
    </Link>
  );
};
const MenuItemWithSub4 = ({ title, path, icon }: any): JSX.Element => {
  const { setIsOpen } = useContext(PrivateContext);
  const location = useLocation();
  const currentPath = location.pathname.toLowerCase();
  const checkHover =
    currentPath === path.myStrategy ||
    currentPath === path.blueprint ||
    currentPath === path.strategy ||
    currentPath === path.bosBeacons;
  return (
    <Link
      to={path.myStrategy}
      className={checkHover ? 'sidebar-link active-sub' : 'sidebar-link'}
    >
      <div className="sidebar-options">
        <span className={'icon active-' + icon}></span>
        <p>{title}</p>
      </div>

      {checkHover && (
        <>
          <Link
            to="/wealth-blueprint"
            className="sidebar-link sub"
            onClick={() => {
              window.scrollTo(0, 0);
              setIsOpen(false);
            }}
          >
            <div
              className={`sidebar-option-${
                currentPath === path.blueprint ? 'f' : 's'
              }-index`}
            >
              <RiLightbulbFlashFill />
              {dictionary['MENU-24-sub-1']}
            </div>
          </Link>
          <Link
            to="/treasury-strategy"
            className="sidebar-link sub"
            onClick={() => {
              window.scrollTo(0, 0);
              setIsOpen(false);
            }}
          >
            <div
              className={`sidebar-option-${
                currentPath === path.strategy ? 'f' : 's'
              }-index`}
            >
              <RiLightbulbFlashFill />
              {dictionary['MENU-24-sub-2']}
            </div>
          </Link>
          <Link
            to="/bos-beacons"
            className="sidebar-link sub"
            onClick={() => {
              window.scrollTo(0, 0);
              setIsOpen(false);
            }}
          >
            <div
              className={`sidebar-option-${
                currentPath === path.bosBeacons ? 'f' : 's'
              }-index`}
            >
              <RiLightbulbFlashFill />
              {dictionary['MENU-24-sub-3']}
            </div>
          </Link>
        </>
      )}
    </Link>
  );
};
const MenuItemWithSubReusable = ({
  title,
  mainPath,
  icon,
  subItems
}: MenuItemWithSubReusableProps): JSX.Element => {
  const { setIsOpen } = useContext(PrivateContext);
  const location = useLocation();
  const currentPath = location.pathname.toLowerCase();

  const checkHover =
    currentPath === mainPath ||
    subItems.some(
      (item) =>
        currentPath === item.path ||
        (item.paths &&
          Object.values(item.paths).some((subPath) => currentPath === subPath))
    );
  return (
    <div className={checkHover ? 'sidebar-link active-sub' : 'sidebar-link'}>
      <Link to={mainPath} className="sidebar-options">
        <span className={'icon active-' + icon}></span>
        <p>{title}</p>
      </Link>

      {checkHover && (
        <>
          {subItems.map((subItem, index) => (
            <>
              {subItem?.isLink ? (
                <a
                  key={index}
                  target="_blank"
                  href={subItem.path}
                  className="sidebar-link sub"
                  // onClick={() => {
                  //   window.scrollTo(0, 0);
                  //   setIsOpen(false);
                  // }}
                >
                  <div
                    className={`sidebar-option-${
                      currentPath === subItem.path ? 'f' : 's'
                    }-index`}
                  >
                    {subItem.icon}
                    {subItem.label}
                  </div>
                </a>
              ) : (
                <Link
                  key={index}
                  to={subItem.path}
                  className="sidebar-link sub flex !items-start !flex-col"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setIsOpen(false);
                  }}
                >
                  <div
                    className={`sidebar-option-${
                      currentPath === subItem.path ? 'f' : 's'
                    }-index`}
                  >
                    {subItem.icon}
                    {subItem.label}
                  </div>
                  {(currentPath === subItem?.paths?.tutorials ||
                    currentPath === subItem?.paths?.dashboardTutorials ||
                    currentPath === subItem?.paths?.depositTutorials ||
                    currentPath === subItem?.paths?.depositCryptoTutorials ||
                    currentPath === subItem?.paths?.depositBankTutorials ||
                    currentPath === subItem?.paths?.cryptoTutorials) && (
                    <div className="flex flex-col">
                      {' '}
                      {(currentPath === subItem?.paths?.tutorials ||
                        currentPath === subItem?.paths?.dashboardTutorials ||
                        currentPath === subItem?.paths?.depositTutorials ||
                        currentPath ===
                          subItem?.paths?.depositCryptoTutorials ||
                        currentPath === subItem?.paths?.depositBankTutorials ||
                        currentPath === subItem?.paths?.cryptoTutorials) && (
                        <div>
                          <Link
                            to="/dashboard-tutorials"
                            className="submenu-3rd sidebar-link sub db-tutorials"
                            onClick={() => {
                              setIsOpen(false);
                              window.scrollTo(0, 0);
                            }}
                          >
                            <div
                              className={`sidebar-option-${
                                currentPath ===
                                subItem?.paths?.dashboardTutorials
                                  ? 'f'
                                  : 's'
                              }-index`}
                            >
                              <FaQuora />
                              {dictionary['MENU-7-sub-11-sub-1']}
                            </div>
                          </Link>
                          <Link
                            to="/crypto-tutorials"
                            className="submenu-3rd sidebar-link sub cr-tutorials"
                            onClick={() => {
                              setIsOpen(false);
                              window.scrollTo(0, 0);
                            }}
                          >
                            <div
                              className={`sidebar-option-${
                                currentPath === subItem?.paths?.cryptoTutorials
                                  ? 'f'
                                  : 's'
                              }-index`}
                            >
                              <FaQuora />
                              {dictionary['MENU-7-sub-11-sub-2']}
                            </div>
                          </Link>
                          <Link
                            to="/deposit-tutorials"
                            className="submenu-3rd sidebar-link sub dep-tutorials"
                            onClick={() => {
                              setIsOpen(false);
                              window.scrollTo(0, 0);
                            }}
                          >
                            <div
                              className={` flex flex-col sidebar-option-${
                                currentPath ===
                                  subItem?.paths?.depositTutorials ||
                                currentPath ===
                                  subItem?.paths?.depositCryptoTutorials ||
                                currentPath ===
                                  subItem?.paths?.depositBankTutorials
                                  ? 'f'
                                  : 's'
                              }-index`}
                            >
                              <span className="flex gap-1 items-center">
                                <FaQuora />
                                {dictionary['MENU-7-sub-11-sub-3']}
                              </span>

                              <div className="pl-4 mt-2 space-y-2">
                                <Link
                                  to={subItem?.paths?.depositCryptoTutorials}
                                  className="block text-md text-gray-600 hover:text-blue-500 transition-colors duration-200"
                                  onClick={() => {
                                    setIsOpen(false);
                                    window.scrollTo(0, 0);
                                  }}
                                >
                                  <div className="flex items-center space-x-2">
                                    <FaQuora className="text-md" />
                                    <span>
                                      {dictionary['MENU-7-sub-11-sub-3-sub-1']}
                                    </span>
                                  </div>
                                </Link>
                                <Link
                                  to={subItem?.paths?.depositBankTutorials}
                                  className="block text-md text-gray-600 hover:text-blue-500 transition-colors duration-200"
                                  onClick={() => {
                                    setIsOpen(false);
                                    window.scrollTo(0, 0);
                                  }}
                                >
                                  <div className="flex items-center space-x-2">
                                    <FaQuora className="text-md" />
                                    <span>
                                      {dictionary['MENU-7-sub-11-sub-3-sub-2']}
                                    </span>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
                </Link>
              )}
            </>
          ))}
        </>
      )}
    </div>
  );
};

export {
  MenuItem,
  MenuItemWithSub,
  MenuItemWithSubReusable,
  MenuItemWithSub2,
  MenuItemWithSub3,
  MenuItemWithSub4
};
